import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './orderDetail.scss';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { prettyName } from '../../utils/nameUtils';
import { prettyDate } from '../../utils/calendarUtils';
import {
  statusTranslatorSimplified,
  getIncompletedStatus
} from '../../utils/statusUtils';
import SERVICES from '../../constants/services';

const OrderData = ({
  contractId,
  loanStatus,
  service,
  status,
  creationFlow,
  group,
  groups,
  client,
  executor,
  createdDate,
  editOrderGroup,
  orderId,
  operators,
  editOperator,
  shortenedShareableUrl,
  shareableKey,
  editService
}) => {
  const [onlyView, setOnlyView] = useState(true);
  const [viewOperator, setViewOperator] = useState(true);
  const [groupVal, setGroupVal] = useState('');
  const [linkCopied, setLinkCopied] = useState(false);
  const [operator, setOperator] = useState({
    value: executor._id,
    label: prettyName(executor.name, executor.paternalLastName, executor.maternalLastName)
  });

  const [editableService, setEditableService] = useState(false);

  useEffect(() => {
    setOperator({
      value: executor._id,
      label: prettyName(executor.name, executor.paternalLastName, executor.maternalLastName)
    });
  }, [executor]);

  const saveChanges = () => {
    editOrderGroup(orderId, groupVal);
    setOnlyView(true);
  };

  const selectOperator = operatorVal => setOperator(operatorVal);

  const saveOperator = () => {
    editOperator(orderId, operator.value);
    setViewOperator(true);
  };

  const copyToClipboard = () => {
    setLinkCopied(true);
    const el = document.createElement('textarea');
    el.value = shortenedShareableUrl || `https://konfio.mx/mi/visita/${orderId}/${shareableKey}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  };

  const [selectService, setSelectService] = useState({
    value: service._id,
    label: service.name
  });

  useEffect(() => {
    setSelectService({
      value: service._id,
      label: service.name
    });
  }, [service]);

  const changeService = () => {
    editService(orderId, selectService.value);
    setEditableService(false);
  };

  return (
    <div>
      <div className="detailSpace">
        <Row>
          <Col md="9" sm="7">
            {editableService ? (
              <Form.Group className="service_select">
                <Select
                  classNamePrefix="service_select"
                  value={selectService}
                  name="selectedService"
                  onChange={val => setSelectService(val)}
                  options={SERVICES}
                  placeholder="Todos"
                />
              </Form.Group>
            ) : (
              <h5>{service.name}</h5>
            )}
          </Col>
          <Col style={{ textAlign: 'right' }}>
            {!editableService ? (
              <Button
                className="editLink"
                variant="link"
                data-testid="editService"
                onClick={() => setEditableService(true)}
              >
                {'Editar '}
                <i className="fa fa-pencil" aria-hidden="true" />
              </Button>
            ) : (
              <Button
                className="outLinePurple"
                variant="outline-primary"
                onClick={changeService}
                data-testid="btnSaveService"
              >
                Guardar
              </Button>
            )}
          </Col>
        </Row>
        <strong className="orderFont">Folio:</strong> {contractId} <br />
        <strong className="orderFont">Estatus del préstamo:</strong> {loanStatus} <br />
        <strong className="orderFont">Creado en:</strong>
        {` ${creationFlow}`} el {prettyDate(createdDate)} <br />
        <strong className="orderFont">Estatus de la visita:</strong>
        <span style={{ color: statusTranslatorSimplified(status).color, fontWeight: '500' }}>
          {` ${statusTranslatorSimplified(status).status}${getIncompletedStatus(
            status
          )}`}
        </span>
        <br />
        <br />
        {!linkCopied ? (
          <Button
            className="outLinePurple"
            variant="outline-primary"
            onClick={copyToClipboard}
            data-testid="copyKankunLink"
          >
            Kancun Link
          </Button>
        ) : (
          <span className="editLink">Se copio el Link!</span>
        )}
      </div>
      <hr />
      <div className="detailSpace">
        <Row>
          <Col>
            {onlyView ? (
              <h5>{group.name}</h5>
            ) : (
              <Form.Control
                as="select"
                onChange={e => setGroupVal(e.target.value)}
                defaultValue={group._id}
                data-testid="selectGroup"
              >
                {groups.map(groupData => (
                  <option key={groupData._id} value={groupData._id}>
                    {groupData.name}
                  </option>
                ))}
              </Form.Control>
            )}
          </Col>
          <Col style={{ textAlign: 'right' }}>
            {onlyView ? (
              <Button
                className="editLink"
                variant="link"
                data-testid="editGroup"
                onClick={() => setOnlyView(false)}
              >
                {'Editar '}
                <i className="fa fa-pencil" aria-hidden="true" />
              </Button>
            ) : (
              <Button
                className="outLinePurple"
                variant="outline-primary"
                onClick={saveChanges}
                data-testid="btnSaveGroup"
              >
                Guardar
              </Button>
            )}
          </Col>
        </Row>
        <strong className="orderFont">ID de la compañia:</strong> {group._id} <br />
      </div>
      {client && (
        <div className="detailSpace">
          <hr />
          <h5>Cliente</h5>
          <strong className="orderFont">Nombre: </strong>
          {prettyName(client.name, client.paternalLastName, client.maternalLastName)} <br />
          <strong className="orderFont">ID del cliente:</strong> {client._id} <br />
          <strong className="orderFont">Teléfono:</strong> {client.phone} <br />
          <strong className="orderFont">Correo:</strong> {client.email} <br />
        </div>
      )}
      <div className="detailSpace">
        <hr />
        <Row>
          <Col>
            <h5>Operador</h5>
          </Col>
          <Col style={{ textAlign: 'right' }}>
            {viewOperator ? (
              <Button
                className="editLink"
                data-testid="editOperator"
                variant="link"
                onClick={() => setViewOperator(false)}
              >
                {'Editar '}
                <i className="fa fa-pencil" aria-hidden="true" />
              </Button>
            ) : (
              <Button
                className="outLinePurple"
                variant="outline-primary"
                onClick={saveOperator}
                data-testid="btnSaveOperator"
              >
                Guardar
              </Button>
            )}
          </Col>
        </Row>
        {!viewOperator ? (
          <Form.Group className="operator_select">
            <br />
            <Select
              value={operator}
              name="selectedOperator"
              classNamePrefix="operator_select"
              onChange={selectOperator}
              options={operators}
              placeholder="Todos"
            />
          </Form.Group>
        ) : (
          <div>
            <strong className="orderFont">Nombre: </strong>
            {prettyName(executor.name, executor.paternalLastName, executor.maternalLastName)} <br />
            <strong className="orderFont">ID del operador:</strong> {executor._id}
            <br />
            <strong className="orderFont">Teléfono:</strong> {executor.phone} <br />
            <strong className="orderFont">Correo:</strong> {executor.email} <br />
          </div>
        )}
      </div>
    </div>
  );
};

OrderData.propTypes = {
  contractId: PropTypes.string,
  loanStatus: PropTypes.string,
  service: PropTypes.shape(),
  creationFlow: PropTypes.string,
  createdDate: PropTypes.string,
  editOrderGroup: PropTypes.func,
  editOperator: PropTypes.func,
  editService: PropTypes.func,
  executor: PropTypes.shape(),
  status: PropTypes.number,
  group: PropTypes.shape(),
  client: PropTypes.shape(),
  orderId: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.shape()),
  operators: PropTypes.arrayOf(PropTypes.shape()),
  shortenedShareableUrl: PropTypes.string,
  shareableKey: PropTypes.string
};

export default OrderData;
