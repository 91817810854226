import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Container } from 'react-bootstrap';
import { hideAlert } from '../../../ducks/alert';

const AlertMessage = ({ showAlert, message, kind, close }) =>
  showAlert && (
    <Container data-testid="alert">
      <Alert variant={kind} onClose={() => close()} dismissible data-testid>
        {message}
      </Alert>
    </Container>
  );

AlertMessage.propTypes = {
  showAlert: PropTypes.bool,
  message: PropTypes.string,
  kind: PropTypes.string
};

const mapStateToProps = ({ alert }) => ({
  showAlert: alert.showAlert,
  message: alert.message,
  kind: alert.kind
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(hideAlert())
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage);
