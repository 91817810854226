import ERROR_MESSAGES from '../constants/errorMessages';
import API_ERRORS from '../constants/apiErrors';

const errorHandler = error => {
  if (error.code === 'ValidationError' && error.path) {
    console.log('ValidationError: ', error.message);
    console.log('path: ', error.path);
    switch (error.context.key) {
      case 'password':
        return ERROR_MESSAGES.INCORRECT_PASSWORD;
      case 'endStatus':
        return ERROR_MESSAGES.STATUS_ERROR;
      default:
        return ERROR_MESSAGES.DEFAULT_ERROR;
    }
  }
  switch (error.name) {
    case API_ERRORS.ADMIN_NOT_FOUND:
      return ERROR_MESSAGES.INCORRECT_USER;
    case API_ERRORS.INCORRECT_PASSWORD:
      return ERROR_MESSAGES.INCORRECT_PASSWORD;
    case API_ERRORS.USER_DUPLICATE_ERROR:
    case API_ERRORS.DUPLICATE_ERROR:
      return ERROR_MESSAGES.DUPLICATE_USER;
    case API_ERRORS.SESSION_EXPIRED:
    case API_ERRORS.SESSION_ERROR:
      return ERROR_MESSAGES.SESSION_EXPIRED;
    default:
      return ERROR_MESSAGES.DEFAULT_ERROR;
  }
};

export default errorHandler;
