import { RECEIVED, SEEN, PRICE_SETTED, PAID, PAID_MANUALLY } from '../constants/statusFlows';

export const orderUnassigned = status => status >= 0 && status < 100;
export const orderAssigned = status => status >= 100 && status < 200;
export const orderOnTheWay = status => status >= 200 && status < 300;
export const orderWithClient = status => status >= 300 && status < 400;
export const orderWithEvidence = status => status >= 400 && status < 500;
export const orderIncompleted = status => status >= 500 && status < 600;
export const orderWasCompleted = status => status >= 600 && status < 699;
export const orderRescheduled = status => status == 51 || status == 151 || status == 160;

export const getIncompletedStatus = status => {
  switch (status) {
    case 501:
      return '- Cliente no quiere prestamo';
    case 502:
      return '- No se encontro domicilio';
    case 503:
      return '- Cliente no esta disponible';
    case 504:
      return '- Cliente tiene dudas';
    case 505:
      return '- Visitador no se presento';
    case 506:
      return '- Faltan firmas';
    case 507:
      return '- Se propone otra fecha';
    default:
      return '';
  }
};

export const statusTranslatorSimplified = status => {
  if (orderRescheduled(status)) {
    return {
      code: status,
      status: 'Reagendada',
      color: '#0E365E'
    };
  }
  if (orderAssigned(status)) {
    return {
      code: status,
      status: 'Asignada',
      color: '#0E365E'
    };
  }
  if (orderIncompleted(status)) {
    return {
      code: status,
      status: 'Incompleta ',
      color: '#FF403B'
    };
  }
  if (orderUnassigned(status)) {
    return {
      code: status,
      status: 'Agendada',
      color: '#666'
    };
  }
  if (orderOnTheWay(status)) {
    return {
      code: status,
      status: 'En Camino',
      color: '#2185d0'
    };
  }
  if (orderWithClient(status)) {
    return {
      code: status,
      status: 'Llego',
      color: 'green'
    };
  }
  if (orderWithEvidence(status)) {
    return {
      code: status,
      status: 'Recolectada',
      color: '#61BD4F'
    };
  }
  if (orderWasCompleted(status)) {
    return {
      code: status,
      status: 'Completada',
      color: '#2D3EE0'
    };
  }
  return {};
};

export const getStatusRange = status => {
  if (status === '') {
    return {
      startStatus: 0,
      endStatus: 899
    };
  }
  if (orderAssigned(status)) {
    return {
      startStatus: 100,
      endStatus: 199
    };
  }
  if (orderIncompleted(status)) {
    return {
      startStatus: 500,
      endStatus: 599
    };
  }
  if (orderUnassigned(status)) {
    return {
      startStatus: 0,
      endStatus: 99
    };
  }
  if (orderOnTheWay(status)) {
    return {
      startStatus: 200,
      endStatus: 299
    };
  }
  if (orderWithClient(status)) {
    return {
      startStatus: 300,
      endStatus: 399
    };
  }
  if (orderWithEvidence(status)) {
    return {
      startStatus: 400,
      endStatus: 499
    };
  }
  if (orderWasCompleted(status)) {
    return {
      startStatus: 500,
      endStatus: 599
    };
  }
  return {
    startStatus: 0,
    endStatus: 899
  };
};

export const flowStatusTranslator = status => {
  switch (status) {
    case RECEIVED:
      return 'Pending';
    case SEEN:
      return 'Pending';
    case PRICE_SETTED:
      return 'Pending';
    case PAID:
      return 'Paid with Astro';
    case PAID_MANUALLY:
      return 'Paid';
    default:
      return '';
  }
};

export const isPending = status => [SEEN, RECEIVED, PRICE_SETTED].includes(status);

export const statusTimeLine = status => {
  const inactive = '#8B8B8B';
  const active = '#825CA4';
  const timelineObj = {
    created: {
      color: inactive,
      showText: false
    },
    assigned: {
      color: inactive,
      showText: false
    },
    onTheWay: {
      color: inactive,
      showText: false
    },
    arrived: {
      color: inactive,
      showText: false
    },
    tookEvidence: {
      color: inactive,
      showText: false
    },
    completed: {
      color: inactive,
      showText: false
    }
  };
  if (orderUnassigned(status)) {
    timelineObj.created.color = active;
    timelineObj.created.showText = true;
  }
  if (orderAssigned(status)) {
    timelineObj.created.color = active;
    timelineObj.created.showText = true;
    timelineObj.assigned.color = active;
    timelineObj.assigned.showText = true;
  }
  if (orderOnTheWay(status)) {
    timelineObj.created.color = active;
    timelineObj.created.showText = true;
    timelineObj.assigned.color = active;
    timelineObj.assigned.showText = true;
    timelineObj.onTheWay.color = active;
    timelineObj.onTheWay.showText = true;
  }
  if (orderWithClient(status)) {
    timelineObj.created.color = active;
    timelineObj.created.showText = true;
    timelineObj.assigned.color = active;
    timelineObj.assigned.showText = true;
    timelineObj.onTheWay.color = active;
    timelineObj.onTheWay.showText = true;
    timelineObj.arrived.color = active;
    timelineObj.arrived.showText = true;
  }
  if (orderWithEvidence(status)) {
    timelineObj.created.color = active;
    timelineObj.created.showText = true;
    timelineObj.assigned.color = active;
    timelineObj.assigned.showText = true;
    timelineObj.onTheWay.color = active;
    timelineObj.onTheWay.showText = true;
    timelineObj.arrived.color = active;
    timelineObj.arrived.showText = true;
    timelineObj.tookEvidence.color = active;
    timelineObj.tookEvidence.showText = true;
  }
  if (orderWasCompleted(status)) {
    timelineObj.created.color = active;
    timelineObj.created.showText = true;
    timelineObj.assigned.color = active;
    timelineObj.assigned.showText = true;
    timelineObj.onTheWay.color = active;
    timelineObj.onTheWay.showText = true;
    timelineObj.arrived.color = active;
    timelineObj.arrived.showText = true;
    timelineObj.tookEvidence.color = active;
    timelineObj.tookEvidence.showText = true;
    timelineObj.completed.color = active;
    timelineObj.completed.showText = true;
  }
  if (orderIncompleted(status)) {
    timelineObj.created.color = inactive;
    timelineObj.created.showText = false;
    timelineObj.assigned.color = inactive;
    timelineObj.assigned.showText = false;
    timelineObj.onTheWay.color = inactive;
    timelineObj.onTheWay.showText = false;
    timelineObj.arrived.color = inactive;
    timelineObj.arrived.showText = false;
    timelineObj.tookEvidence.color = inactive;
    timelineObj.tookEvidence.showText = false;
    timelineObj.completed.color = inactive;
    timelineObj.completed.showText = false;
  }
  return timelineObj;
};
