import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs, Tab, Container, Row, Col } from 'react-bootstrap';
import Map from './map.orderDetail';
import OrderData from './data.orderDetail';
import {
  getUniqueOrder,
  editGroupOrder,
  editLocationOrder,
  getOperators,
  changeOperator,
  editServiceOrder
} from '../../ducks/orderDetail';
import { getGroupList } from '../../ducks/groups';
import { getUniqueLoanInfo } from '../../ducks/loanInfo';
import Visit from './visit.orderDetail';
import Steps from './steps.orderDetail';
import './orderDetail.scss';

const OrderDetail = ({
  getOrder,
  getLoanInfo,
  paramId,
  orderDetail,
  groups,
  loanInfo,
  getGroups,
  editOrderGroup,
  editService,
  editOrderLocation,
  editOperator,
  listOperators
}) => {
  useEffect(() => {
    getOrder(paramId);
    getLoanInfo(paramId);
    getGroups();
    listOperators();
  }, []);
  return (
    <Container className="orderBox">
      <Row>
        <Col md="5" xs="7">
          <Tabs defaultActiveKey="order" id="uncontrolled-tab-example">
            <Tab eventKey="order" title="Detalle de la Orden">
              <OrderData
                contractId={orderDetail.contractNumber}
                loanStatus={loanInfo.loanStatus || '-'}
                service={orderDetail.service}
                creationFlow={orderDetail.flowFamily}
                createdDate={orderDetail.createdDate}
                shortenedShareableUrl={orderDetail.shortenedShareableUrl}
                shareableKey={orderDetail.shareableKey}
                status={orderDetail.status}
                group={orderDetail.group}
                client={orderDetail.client}
                executor={orderDetail.executor}
                groups={groups}
                orderId={paramId}
                operators={orderDetail.operators}
                editOrderGroup={editOrderGroup}
                editOperator={editOperator}
                editService={editService}
              />
            </Tab>
            <Tab eventKey="visit" title="Detalle de la Visita">
              <Visit
                instructions={orderDetail.instructions}
                location={orderDetail.location.prettyAddress}
                interior={orderDetail.location.interior || 'No tiene'}
                evidences={orderDetail.evidence}
                documents={orderDetail.documents}
              />
            </Tab>
          </Tabs>
        </Col>
        <Col md="3" xs="5">
          <Steps
            status={orderDetail.status}
            scheduledDate={orderDetail.scheduledDate}
            operator={orderDetail.executor}
          />
        </Col>
        <Col md="4">
          <Map
            lat={orderDetail.location.latitude}
            lng={orderDetail.location.longitude}
            typedAddress={orderDetail.location.typedAddress}
            editOrderLocation={editOrderLocation}
            orderId={paramId}
          />
        </Col>
      </Row>
    </Container>
  );
};

OrderDetail.propTypes = {
  getOrder: PropTypes.func,
  getLoanInfo: PropTypes.func,
  paramId: PropTypes.string,
  getGroups: PropTypes.func,
  editOrderGroup: PropTypes.func,
  orderDetail: PropTypes.shape(),
  loanInfo: PropTypes.shape(),
  groups: PropTypes.arrayOf(PropTypes.shape),
  editOrderLocation: PropTypes.func,
  editOperator: PropTypes.func,
  editService: PropTypes.func,
  listOperators: PropTypes.arrayOf(PropTypes.shape)
};

const mapStateToProps = ({ orderDetail, groups, loanInfo }, { match }) => ({
  paramId: match.params.orderId,
  orderDetail,
  groups,
  loanInfo,
});

const mapDispatchToProps = dispatch => ({
  getOrder: orderId => dispatch(getUniqueOrder(orderId)),
  getGroups: () => dispatch(getGroupList()),
  getLoanInfo: orderId => dispatch(getUniqueLoanInfo(orderId)),
  listOperators: () => dispatch(getOperators()),
  editOrderGroup: (orderId, groupId) => dispatch(editGroupOrder(orderId, groupId)),
  editOrderLocation: (orderId, location) => dispatch(editLocationOrder(orderId, location)),
  editOperator: (orderId, operatorId) => dispatch(changeOperator(orderId, operatorId)),
  editService: (orderId, serviceId) => dispatch(editServiceOrder(orderId, serviceId))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
