import api from '../api';

// eslint-disable-next-line import/prefer-default-export
export const getGroups = () =>
  new Promise((resolve, reject) => {
    api
      .get('/groups')
      .then(response => {
        resolve(response.data);
      })
      .catch(error => reject(error.data));
  });
