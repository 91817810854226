import React, { useState, useEffect } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { doLogin, logOut } from '../../ducks/user';
import './login.scss';
import ErrorMessage from '../common/errorMessage/errorMessage.index';

const Login = ({ login, clearUser }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passError, setPassError] = useState('');
  useEffect(() => {
    clearUser();
  }, []);
  return (
    <Container data-testid="login" className="loginBox">
      <Form>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Correo Electrónico</Form.Label>
          <Form.Control
            value={email}
            data-testid="emailLogin"
            onChange={event => {
              setEmailError('');
              setEmail(event.target.value);
            }}
            type="email"
          />
          <ErrorMessage error={emailError} dataTestId="errorEmail" />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Contraseña</Form.Label>
          <Form.Control
            value={password}
            type="password"
            data-testid="passLogin"
            onChange={event => {
              setPassError('');
              setPassword(event.target.value);
            }}
          />
          <ErrorMessage error={passError} dataTestId="errorPass" />
        </Form.Group>
        <Button
          data-testid="enterBtn"
          className="btnLogin"
          block
          type="submit"
          onClick={e => {
            e.preventDefault();
            if (email === '') {
              setEmailError('Por favor, ingrese un correo válido');
            }
            if (password === '') {
              setPassError('Por favor, ingrese su contraseña');
            }
            if (email !== '' && password !== '') {
              login(email, password);
            }
          }}
        >
          Entrar
        </Button>
      </Form>
    </Container>
  );
};

Login.propTypes = {
  login: PropTypes.func,
  clearUser: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(doLogin(email, password)),
  clearUser: () => dispatch(logOut())
});

export default connect(null, mapDispatchToProps)(Login);
