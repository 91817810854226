const mexStates = [
  {
    value: '1',
    label: 'Aguascalientes'
  },
  {
    value: '2',
    label: 'Baja California'
  },
  {
    value: '3',
    label: 'Baja California Sur'
  },
  {
    value: '4',
    label: 'Campeche'
  },
  {
    value: '5',
    label: 'Chiapas'
  },
  {
    value: '6',
    label: 'Chihuahua'
  },
  {
    value: '7',
    label: 'Coahuila'
  },
  {
    value: '8',
    label: 'Colima'
  },
  {
    value: '9',
    label: 'Ciudad de México'
  },
  {
    value: '10',
    label: 'Durango'
  },
  {
    value: '11',
    label: 'Guanajuato'
  },
  {
    value: '12',
    label: 'Guerrero'
  },
  {
    value: '13',
    label: 'Hidalgo'
  },
  {
    value: '14',
    label: 'Jalisco'
  },
  {
    value: '15',
    label: 'Mexico'
  },
  {
    value: '16',
    label: 'Michoacan'
  },
  {
    value: '17',
    label: 'Morelos'
  },
  {
    value: '18',
    label: 'Nayarit'
  },
  {
    value: '19',
    label: 'Nuevo Leon'
  },
  {
    value: '20',
    label: 'Oaxaca'
  },
  {
    value: '21',
    label: 'Puebla'
  },
  {
    value: '22',
    label: 'Queretaro'
  },
  {
    value: '23',
    label: 'Quintana Roo'
  },
  {
    value: '24',
    label: 'San Luis Potosi'
  },
  {
    value: '25',
    label: 'Sinaloa'
  },
  {
    value: '26',
    label: 'Sonora'
  },
  {
    value: '27',
    label: 'Tabasco'
  },
  {
    value: '28',
    label: 'Tamaulipas'
  },
  {
    value: '29',
    label: 'Tlaxcala'
  },
  {
    value: '30',
    label: 'Veracruz'
  },
  {
    value: '31',
    label: 'Yucatan'
  },
  {
    value: '32',
    label: 'Zacatecas'
  }
];

export default mexStates;
