import { orderDetail as initialState } from '../config/initialState.json';
import {
  getOrderDetail,
  editOrderGroup,
  editOrderLocation,
  editOperator,
  editOrderService
} from '../api/services/order';
import { listOperators } from '../api/services/user';
import { showLoader, hideLoader } from './loader';
import { showAlert } from './alert';
import { prettyName } from '../utils/nameUtils';
import errorHandler from '../utils/errorHandler';

// ACTIONS
export const SET_ORDER_DETAIL = 'SET_ORDER_DETAIL';
export const SET_OPERATORS = 'SET_OPERATORS';

export const setOrders = orders => ({ type: SET_ORDER_DETAIL, orders });

export const setOrderDetail = orderDetail => ({ type: SET_ORDER_DETAIL, orderDetail });

export const setOperators = operators => ({ type: SET_OPERATORS, operators });

export const getUniqueOrder = orderId => dispatch => {
  dispatch(showLoader());
  getOrderDetail(orderId)
    .then(response => {
      dispatch(setOrderDetail(response));
      dispatch(hideLoader());
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

export const editGroupOrder = (orderId, groupId) => dispatch => {
  if (groupId !== '') {
    dispatch(showLoader());
    editOrderGroup(orderId, groupId)
      .then(response => {
        dispatch(setOrderDetail(response));
        dispatch(hideLoader());
      })
      .catch(error => {
        dispatch(hideLoader());
        dispatch(showAlert('danger', errorHandler(error)));
      });
  }
};

export const editLocationOrder = (orderId, location) => dispatch => {
  dispatch(showLoader());
  editOrderLocation(orderId, location)
    .then(response => {
      dispatch(setOrderDetail(response));
      dispatch(hideLoader());
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

export const editServiceOrder = (orderId, serviceId) => dispatch => {
  dispatch(showLoader());
  editOrderService(orderId, serviceId)
    .then(response => {
      dispatch(setOrderDetail(response));
      dispatch(hideLoader());
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

export const getOperators = () => dispatch => {
  dispatch(showLoader());
  listOperators()
    .then(response => {
      const newOperators = response.map(operator => ({
        value: operator._id,
        label: `${prettyName(
          operator.name,
          operator.paternalLastName,
          operator.maternalLastName
        )} (${operator.group.name})`
      }));
      dispatch(setOperators(newOperators));
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

export const changeOperator = (orderId, operatorId) => dispatch => {
  dispatch(showLoader());
  editOperator(orderId, operatorId)
    .then(response => {
      dispatch(setOrderDetail(response));
      dispatch(hideLoader());
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

// REDUCER
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_DETAIL:
      return {
        ...state,
        ...action.orderDetail
      };
    case SET_OPERATORS:
      return {
        ...state,
        operators: action.operators
      };
    default:
      return state;
  }
};
