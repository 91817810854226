import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './loader.scss';

const Loader = ({ showLoader }) =>
  showLoader && (
    <div data-testid="loader" className="contLoader">
      <div className="loader" />
    </div>
  );

Loader.propTypes = {
  showLoader: PropTypes.bool
};

const mapStateToProps = ({ loader }) => ({
  showLoader: loader
});

export default connect(mapStateToProps, null)(Loader);
