import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Container, Form, Row, Col, Card } from 'react-bootstrap';
import { DateRangePicker } from 'react-dates';
import useDateRange from '../../hooks/useDateRange';
import WeekDaysChart from './weekDaysChart.reports';
import StatusPieChart from './statusPieChart.reports';
import OperatorsChart from './operatorsChart.reports';
import AvgTimePieChart from './avgTimePieChart.reports';
import VisitsCanceledChart from './visitsChart.reports';
import {
  listOrdersByWeekDay,
  getStatusOrders,
  getOrdersCanceled,
  getAverageTime,
  getOperatorsData
} from '../../ducks/reports';
import mexStates from '../../constants/mexStates';

import './reports.scss';

const Reports = ({
  ordersByDayOfWeek,
  getOrdersbyWeekDay,
  getOrdersByStatus,
  ordersByStatus,
  getCanceledOrders,
  canceledOrders,
  getAvgTime,
  averageTime,
  operatorsData,
  getOperatorsInfo
}) => {
  const { focusInput, setFocusInput, dateStart, dateEnd, filterByDate } = useDateRange();

  const getStartDate = days =>
    moment()
      .subtract(days, 'days')
      .startOf('day');

  const [dateRange, setDateRange] = useState(false);

  const [stateVal, setStateVal] = useState(null);
  const filterByState = selectedOption => setStateVal(selectedOption);

  const changeDate = event => {
    const selectVal = event.target.value;
    if (selectVal === 'showDateRange') {
      setDateRange(true);
    } else {
      setDateRange(false);
      filterByDate({
        startDate: getStartDate(selectVal),
        endDate: moment().startOf('day')
      });
    }
  };

  useEffect(() => {
    const stateValue = stateVal ? stateVal.value : null;
    getOrdersbyWeekDay(dateStart, dateEnd, stateValue);
    getOrdersByStatus(dateStart, dateEnd, stateValue);
    getCanceledOrders(dateStart, dateEnd, stateValue);
    getAvgTime(dateStart, dateEnd, stateValue);
    getOperatorsInfo(dateStart, dateEnd, stateValue);
  }, [dateStart, dateEnd, stateVal]);

  return (
    <Container>
      <Form>
        <Form.Group controlId="exampleForm.ControlSelect2">
          <Row>
            <Col md="6" sm="12">
              <Form.Label className="subTextOrder">Filtrar Por:</Form.Label>
              <Form.Control as="select" data-testid="filterDays" onChange={changeDate}>
                <option value={30}>Ultimos 30 días</option>
                <option value={15}>Ultimos 15 días</option>
                <option value={7}>Ultimos 7 días</option>
                <option value="showDateRange">Personalizado</option>
              </Form.Control>
            </Col>
            <Col md="6" className="state_select">
              <Form.Label className="subTextOrder">Estado</Form.Label>
              <Select
                classNamePrefix="state_select"
                value={stateVal}
                onChange={filterByState}
                options={mexStates}
                placeholder="Todos"
                isClearable
              />
            </Col>
            {dateRange && (
              <Col md="6" sm="12">
                <br />
                <Form.Label className="subTextOrder">Rango de Fechas</Form.Label>
                <DateRangePicker
                  startDateId="start_date_id"
                  minimumNights={0}
                  endDateId="end_date_id"
                  startDate={dateStart}
                  startDatePlaceholderText="Fecha Inicial"
                  endDatePlaceholderText="Fecha Final"
                  endDate={dateEnd}
                  onDatesChange={filterByDate}
                  isOutsideRange={() => false}
                  focusedInput={focusInput}
                  hideKeyboardShortcutsPanel
                  onFocusChange={focusedInput => setFocusInput(focusedInput)}
                />
              </Col>
            )}
          </Row>
        </Form.Group>
      </Form>
      <Row>
        <Col lg="6" md="12">
          <Card>
            <Card.Header className="cardHeader">
              <h5>Órdenes Por Día de la Semana</h5>
            </Card.Header>
            <Card.Body>
              <WeekDaysChart ordersByDayOfWeek={ordersByDayOfWeek} />
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6" md="12">
          <Card>
            <Card.Header className="cardHeader">
              <h5>Órdenes Por Status</h5>
            </Card.Header>
            <Card.Body className="cardNotPadd">
              <StatusPieChart ordersByStatus={ordersByStatus} />
            </Card.Body>
          </Card>
        </Col>
        <Col md="12">
          <Card>
            <Card.Header className="cardHeader">
              <h5>Órdenes Fallidas Por Operador</h5>
            </Card.Header>
            <Card.Body>
              <OperatorsChart operatorsData={operatorsData} />
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6" md="12">
          <Card>
            <Card.Header className="cardHeader">
              <h5>Órdenes Incompletas</h5>
            </Card.Header>
            <Card.Body className="cardNotPadd">
              <VisitsCanceledChart canceledOrders={canceledOrders} />
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6" md="12">
          <Card>
            <Card.Header className="cardHeader">
              <h5>Tiempo Promedio Por Orden</h5>
            </Card.Header>
            <Card.Body className="cardNotPadd">
              <AvgTimePieChart averageTime={averageTime} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

Reports.propTypes = {
  ordersByDayOfWeek: PropTypes.arrayOf(PropTypes.shape()),
  operatorsData: PropTypes.arrayOf(PropTypes.shape()),
  canceledOrders: PropTypes.arrayOf(PropTypes.shape()),
  getOrdersbyWeekDay: PropTypes.func,
  getOrdersByStatus: PropTypes.func,
  getCanceledOrders: PropTypes.func,
  getAvgTime: PropTypes.func,
  getOperatorsInfo: PropTypes.func,
  ordersByStatus: PropTypes.shape(),
  averageTime: PropTypes.shape()
};

const mapStateToProps = ({ reports }) => ({
  ordersByDayOfWeek: reports.ordersByDayOfWeek,
  ordersByStatus: reports.ordersByStatus,
  canceledOrders: reports.canceledOrders,
  averageTime: reports.averageTime,
  operatorsData: reports.operatorsData
});

const mapDispatchToProps = dispatch => ({
  getOrdersbyWeekDay: (startDate, endDate, state) =>
    dispatch(listOrdersByWeekDay(startDate, endDate, state)),
  getOrdersByStatus: (startDate, endDate, state) =>
    dispatch(getStatusOrders(startDate, endDate, state)),
  getAvgTime: (startDate, endDate, state) => dispatch(getAverageTime(startDate, endDate, state)),
  getCanceledOrders: (startDate, endDate, state) =>
    dispatch(getOrdersCanceled(startDate, endDate, state)),
  getOperatorsInfo: (startDate, endDate, state) =>
    dispatch(getOperatorsData(startDate, endDate, state))
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
