import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Geosuggest from 'react-geosuggest';
import { Row, Col, Button, Form } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';
import './orderDetail.scss';
import mexStates from '../../constants/mexStates';
import ErrorMessage from '../common/errorMessage/errorMessage.index';
import ERROR_MESSAGES from '../../constants/errorMessages';

const MapComponent = ({ lat, lng, typedAddress, editOrderLocation, orderId }) => {
  const [editPin, setEditPin] = useState(false);
  const [selectError, setSelectError] = useState('');
  const [locationError, setLocationError] = useState('');

  let useGoogleMap = useRef(null);
  const [suggestPin, setSuggestPin] = useState({
    latitude: lat,
    longitude: lng,
    name: '',
    rawAddress: '',
    prettyAddress: '',
    googlePlaceId: '',
    writtenLocation: false,
    state: null,
    interior: ''
  });

  const [state, setState] = useState(null);

  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    places.forEach(place => {
      bounds.extend(new maps.LatLng(place.latitude, place.longitude));
    });
    return bounds;
  };

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  const suggestSelected = suggest => {
    if (suggest && suggest.location) {
      const map = useGoogleMap.map_;
      const maps = useGoogleMap.maps_;

      const tempPin = {
        latitude: lat,
        longitude: lng
      };
      const tempSuggestPin = {
        latitude: suggest.location.lat,
        longitude: suggest.location.lng,
        writtenLocation: true,
        name: suggest.gmaps.name,
        rawAddress: suggest.description,
        prettyAddress: suggest.gmaps.formatted_address,
        googlePlaceId: suggest.placeId
      };
      const places = [tempPin, tempSuggestPin];
      const bounds = getMapBounds(map, maps, places);

      map.fitBounds(bounds);
      bindResizeListener(map, maps, bounds);
      setSuggestPin(tempSuggestPin);
      setLocationError('');
    }
  };

  const changeInterior = event => {
    const intVal = event.target.value;
    setSuggestPin({
      ...suggestPin,
      interior: intVal
    });
  };

  const saveChanges = () => {
    if (state === null) {
      setSelectError(ERROR_MESSAGES.PLEASE_SELECT_STATE);
    }
    if (!suggestPin.writtenLocation) {
      setLocationError(ERROR_MESSAGES.TYPE_VALID_ADDRESS);
    } else if (state && suggestPin.writtenLocation) {
      const locationObj = suggestPin;
      locationObj.state = state.value;
      delete locationObj.writtenLocation;
      editOrderLocation(orderId, locationObj);
      setEditPin(false);
      setSuggestPin({
        ...suggestPin,
        writtenLocation: false
      });
    }
  };

  const filterByState = selectedOption => {
    setSelectError('');
    setState(selectedOption);
  };

  return (
    <div className="mapContainer">
      <div className="detailSpace">
        <Row>
          <Col sm="7">
            <h5>
              {'Dirección Escrita '}
              <i className="fa fa-location-arrow" aria-hidden="true" />
            </h5>
          </Col>
          <Col className="rightContent">
            {!editPin ? (
              <Button
                className="editLink"
                variant="link"
                data-testid="editLocation"
                onClick={() => setEditPin(true)}
              >
                {'Editar '}
                <i className="fa fa-pencil" aria-hidden="true" />
              </Button>
            ) : (
              <Button className="editLink" variant="link" onClick={() => setEditPin(false)}>
                {'Cancelar '}
                <i className="fa fa-times" aria-hidden="true" />
              </Button>
            )}
          </Col>
        </Row>
        <p>{typedAddress || 'No tiene'}</p>
        {editPin && (
          <Row>
            <Col md="12" style={{ marginBottom: '10px' }} className="state_select">
              <Select
                value={state}
                classNamePrefix="state_select"
                onChange={filterByState}
                options={mexStates}
                placeholder="Selecciona un estado"
              />
              <ErrorMessage error={selectError} />
            </Col>
            <Col md="12" style={{ marginBottom: '10px' }}>
              <Geosuggest
                placeholder="Escribe la dirección aquí"
                className="inputGooglePlace"
                onSuggestSelect={suggestSelected}
              />
              <ErrorMessage error={locationError} />
            </Col>
            <Col md="12" lg="8">
              <Form.Control type="text" onChange={changeInterior} placeholder="Interior" />
            </Col>
            <Col md="12" lg="4" className="rightContent">
              <Button
                className="outLinePurple"
                variant="outline-primary"
                onClick={saveChanges}
                data-testid="saveLocation"
              >
                Guardar
              </Button>
            </Col>
          </Row>
        )}
      </div>
      <br />
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyClb6VyLa45iazk87wGK3oHqrAGnEKfkek' }}
        center={{
          lat,
          lng
        }}
        ref={ref => {
          useGoogleMap = ref;
        }}
        defaultZoom={15}
        resetBoundsOnResize
      >
        <i className="fa fa-map-marker marker" aria-hidden="true" lat={lat} lng={lng} />
        {suggestPin.writtenLocation && (
          <i
            className="fa fa-location-arrow marker markerPurple"
            aria-hidden="true"
            lat={suggestPin.latitude}
            lng={suggestPin.longitude}
          />
        )}
      </GoogleMapReact>
    </div>
  );
};

MapComponent.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  typedAddress: PropTypes.string,
  editOrderLocation: PropTypes.func,
  orderId: PropTypes.string
};

export default MapComponent;
