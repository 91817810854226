import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import TableOrder from './table.orderList';
import { getOrders } from '../../ducks/orderList';
import mexStates from '../../constants/mexStates';
import { getStatusRange } from '../../utils/statusUtils';
import { getGroupList } from '../../ducks/groups';
import history from '../../utils/history';
import { setInitialTime, setFinalTime } from '../../utils/calendarUtils';
import './orderList.scss';

const OrderList = ({ ordersList, getOrdersList, getGroup, groups, match }) => {
  const { startDt, endDt, stateParam, statusParam, groupParam } = match.params;
  const defaultStartDate = moment(startDt) || moment().subtract(30, 'days');
  const defaultEndDate = moment(endDt) || moment();

  const defaultState = mexStates.filter(mexState => mexState.value === stateParam)[0];
  const [focusInput, setFocusInput] = useState(null);
  const [dateStart, setDateStart] = useState(defaultStartDate);
  const [dateEnd, setDateEnd] = useState(defaultEndDate);
  const [stateVal, setStateVal] = useState(defaultState || null);
  const [status, setStatus] = useState(statusParam);
  const [page, setPage] = useState(0);
  const [groupId, setGroupId] = useState(groupParam);

  useEffect(() => {
    const location = stateVal ? stateVal.value : false;
    const { startStatus, endStatus } = getStatusRange(status);
    const url = `/orderList/${setInitialTime(dateStart)}/${setFinalTime(
      dateEnd
    )}/${location}/${status}/${groupId}`;
    history.push(url);
    getOrdersList(
      setInitialTime(dateStart),
      setFinalTime(dateEnd),
      page,
      startStatus,
      endStatus,
      location,
      groupId
    );
    getGroup();
  }, [dateStart, dateEnd, page, stateVal, status, groupId]);

  const filterByDate = ({ startDate, endDate }) => {
    setPage(0);
    setDateStart(moment(startDate));
    setDateEnd(moment(endDate));
  };

  const filterByState = selectedOption => {
    setPage(0);
    setStateVal(selectedOption);
  };

  const filterByStatus = event => {
    setPage(0);
    setStatus(event.target.value);
  };

  const filterByGroup = event => {
    setPage(0);
    setGroupId(event.target.value);
  };

  return (
    <Container>
      <Form>
        <Form.Group>
          <Row>
            <Col lg="4" md="6" sm="12">
              <Form.Label className="subTextOrder">Rango de Fechas</Form.Label>
              <DateRangePicker
                startDateId="start_date_id"
                minimumNights={0}
                endDateId="end_date_id"
                startDate={dateStart}
                startDatePlaceholderText="Fecha Inicial"
                endDatePlaceholderText="Fecha Final"
                endDate={dateEnd}
                onDatesChange={filterByDate}
                isOutsideRange={() => false}
                focusedInput={focusInput}
                hideKeyboardShortcutsPanel
                onFocusChange={focusedInput => setFocusInput(focusedInput)}
              />
            </Col>
            <Col lg="3" md="6" sm="12">
              <Form.Label className="subTextOrder">Estado</Form.Label>
              <Select
                className="inputExtraHeight"
                value={stateVal}
                onChange={filterByState}
                options={mexStates}
                placeholder="Todos"
                isClearable
              />
            </Col>
            <Col lg="3" md="6" sm="12">
              <Form.Label className="subTextOrder">Status</Form.Label>
              <Form.Control
                as="select"
                className="inputExtraHeight"
                value={status}
                onChange={filterByStatus}
              >
                <option value="">Todas</option>
                <option value={0}>Agendadas</option>
                <option value={100}>Asignadas</option>
                <option value={200}>En camino</option>
                <option value={300}>Llego con cliente</option>
                <option value={400}>Evidencia recolectada</option>
                <option value={500}>Incompletas</option>
              </Form.Control>
            </Col>
            <Col lg="2" md="6" sm="12">
              <Form.Label className="subTextOrder">Grupo</Form.Label>
              <Form.Control as="select" onChange={filterByGroup} value={groupId}>
                <option value="">Todos</option>
                {groups.map(group => (
                  <option key={group._id} value={group._id}>
                    {group.name}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>
      </Form>
      <br />
      <TableOrder ordersList={ordersList} page={page} setPage={setPage} />
    </Container>
  );
};

OrderList.propTypes = {
  ordersList: PropTypes.arrayOf(PropTypes.shape()),
  getOrdersList: PropTypes.func,
  getGroup: PropTypes.func,
  groups: PropTypes.shape(),
  match: PropTypes.shape()
};

const mapStateToProps = ({ ordersList, groups }) => ({
  ordersList,
  groups
});

const mapDispatchToProps = dispatch => ({
  getOrdersList: (startDate, endDate, page, startStatus, endStatus, state, group) =>
    dispatch(getOrders(startDate, endDate, page, startStatus, endStatus, state, group)),
  getGroup: () => dispatch(getGroupList())
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
