import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import { Row, Container, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CardBoard from './card.board';
import { getOrders } from '../../ducks/board';
import { getGroupList } from '../../ducks/groups';
import { utcDateZero, addDate } from '../../utils/calendarUtils';
import { socketApiKey, socketSecretKey, socketUrl } from '../../constants/socketKeys';

const Board = ({ getOrderList, orders, groups, getGroups }) => {
  const defaultDate = new Date();
  const startDate = utcDateZero(defaultDate);
  const endDate = addDate(startDate, 'days', 3);

  useEffect(() => {
    getOrderList(startDate, endDate);
    getGroups();
  }, []);

  useEffect(() => {
    if (groups.length > 0) {
      const socket = io(socketUrl);
      const channels = groups.map(({ _id }) => _id);
      socket.on('connect', () => {
        socket.emit('authentication', {
          apiKey: socketApiKey,
          apiSecret: socketSecretKey
        });
      });
      socket.on('authenticated', () => {
        console.log('Authentication success!');
      });
      socket.on('unauthorized', err => {
        console.log('There was an error with the authentication:', err.message);
      });
      channels.forEach(channel => {
        socket.on(channel, payload => {
          if (payload) {
            getOrderList(startDate, endDate);
          }
        });
      });
    }
  }, [groups.length]);

  return (
    <Container fluid>
      <Row className="rowBoard">
        <Col data-testid="unassign" className="colBoard rightBorder" xs="12" md="4" lg="3">
          <h3 className="boardTitle">Sin Asignar</h3>
          {orders.unassigned.map(order => (
            <CardBoard key={order._id} order={order} />
          ))}
        </Col>
        <Col data-testid="assign" className="colBoard rightBorder" xs="12" md="4" lg="3">
          <h3 className="boardTitle">Asignadas</h3>
          {orders.assigned.map(order => (
            <CardBoard key={order._id} order={order} />
          ))}
        </Col>
        <Col data-testid="ongoing" className="colBoard rightBorder" xs="12" md="4" lg="3">
          <h3 className="boardTitle">En camino</h3>
          {orders.ongoing.map(order => (
            <CardBoard key={order._id} order={order} />
          ))}
        </Col>
        <Col data-testid="finish" className="colBoard rightBorder" xs="12" md="4" lg="3">
          <h3 className="boardTitle">Finalizada</h3>
          {orders.finished.map(order => (
            <CardBoard key={order._id} order={order} />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

Board.propTypes = {
  userId: PropTypes.string,
  getOrderList: PropTypes.func,
  getGroups: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.shape()),
  orders: PropTypes.shape()
};

const mapStateToProps = ({ board, groups }) => ({
  orders: board.orders,
  groups
});

const mapDispatchToProps = dispatch => ({
  getOrderList: (startDate, endDate) => dispatch(getOrders(startDate, endDate)),
  getGroups: () => dispatch(getGroupList())
});

export default connect(mapStateToProps, mapDispatchToProps)(Board);
