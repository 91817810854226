import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Modal, Form, Button, InputGroup } from 'react-bootstrap';
import { OPERATOR_PROFILE, AGENT_PROFILE, ADMIN_PROFILE } from '../../constants/profiles';
import ValidatePassword from '../../utils/validatePassword';
import mexStates from '../../constants/mexStates';
import ERROR_MESSAGES from '../../constants/errorMessages';
import { createNewUser } from '../../ducks/user';
import './userList.scss';

const CreateModal = ({ modal, handleModal, groups, createUser }) => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [paternalLN, setPaternalLN] = useState('');
  const [paternalLNErr, setPaternalLNErr] = useState('');
  const [maternalLN, setMaternalLN] = useState('');
  const [maternalLNErr, setMaternalLNErr] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [pass, setPass] = useState('');
  const [passError, setPassError] = useState(false);
  const [tel, setTel] = useState('');
  const [telError, setTelError] = useState('');
  const [groupId, setGroupId] = useState('');
  const [profile, setProfile] = useState(OPERATOR_PROFILE);
  const [stateVal, setStateVal] = useState(mexStates[8]);

  const passTextStyle = classnames({
    'text-muted': true,
    errorText: passError
  });

  const submitForm = () => {
    if (name === '') {
      setNameError(ERROR_MESSAGES.NAME_INVALID);
    }
    if (paternalLN === '') {
      setPaternalLNErr(ERROR_MESSAGES.PATERNAL_LN_INVALID);
    }
    if (maternalLN === '') {
      setMaternalLNErr(ERROR_MESSAGES.MATERNAL_LN_INVALID);
    }
    if (email === '') {
      setEmailError(ERROR_MESSAGES.EMAIL_INVALID);
    }
    if (tel === '') {
      setTelError(ERROR_MESSAGES.TELEPHONE_INVALID);
    }
    if (!ValidatePassword(pass)) {
      setPassError(true);
    }

    if (
      name !== '' &&
      paternalLN !== '' &&
      maternalLN !== '' &&
      email !== '' &&
      ValidatePassword(pass) &&
      tel !== ''
    ) {
      const newUser = {
        name,
        paternalLastName: paternalLN,
        maternalLastName: maternalLN,
        email,
        phone: `+52${tel}`,
        password: pass,
        group: groupId,
        permissions: profile,
        state: stateVal.value
      };
      createUser(newUser);
      handleModal(!modal);
    }
  };

  const setVal = (event, setState, setErrorState) => {
    const val = event.target.value;
    setState(val);
    if (val !== '') {
      setErrorState('');
    }
  };

  const validatePass = event => {
    const val = event.target.value;
    setPass(val);
    if (ValidatePassword(pass)) {
      setPassError(false);
    } else {
      setPassError(true);
    }
  };

  const filterByState = selectedOption => setStateVal(selectedOption);

  useEffect(() => {
    if (groups.length > 0) {
      setGroupId(groups[0]._id);
    }
  }, [groups.length]);

  return (
    <div>
      <Modal show={modal} onHide={() => handleModal(!modal)}>
        <Modal.Header closeButton>
          <Modal.Title>Crear Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                data-testid="inputName"
                onChange={event => setVal(event, setName, setNameError)}
              />
              <Form.Text data-testid="nameError" className="text-muted errorText">
                {nameError}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>Apellido Paterno</Form.Label>
              <Form.Control
                data-testid="inputPaternalLN"
                onChange={event => setVal(event, setPaternalLN, setPaternalLNErr)}
              />
              <Form.Text data-testid="paternalLNErr" className="text-muted errorText">
                {paternalLNErr}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>Apellido Materno</Form.Label>
              <Form.Control
                data-testid="inputMaternalLN"
                onChange={event => setVal(event, setMaternalLN, setMaternalLNErr)}
              />
              <Form.Text data-testid="maternalLNErr" className="text-muted errorText">
                {maternalLNErr}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Correo</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                data-testid="inputEmail"
                onChange={event => setVal(event, setEmail, setEmailError)}
              />
              <Form.Text data-testid="emailError" className="text-muted errorText">
                {emailError}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                data-testid="inputPass"
                onChange={validatePass}
              />
              <Form.Text data-testid="passError" className={passTextStyle}>
                {ERROR_MESSAGES.PASSWORD_INVALID}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>Teléfono</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">+52</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  data-testid="inputTel"
                  onChange={event => setVal(event, setTel, setTelError)}
                />
              </InputGroup>
              <Form.Text data-testid="telError" className="text-muted errorText">
                {telError}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>Estado</Form.Label>
              <Select
                value={stateVal}
                onChange={filterByState}
                options={mexStates}
                placeholder="Todos"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Selecciona un grupo</Form.Label>
              <Form.Control as="select" onChange={event => setGroupId(event.target.value)}>
                {groups.map(group => (
                  <option key={group._id} value={group._id}>
                    {group.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Selecciona un perfil</Form.Label>
              <Form.Control
                as="select"
                onChange={event => setProfile(event.target.value.split(','))}
              >
                <option value={OPERATOR_PROFILE}>Operador</option>
                <option value={AGENT_PROFILE}>Agente</option>
                <option value={ADMIN_PROFILE}>Administrador</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-testid="btnCreate"
            variant="primary"
            className="purpleBtn"
            block
            onClick={submitForm}
          >
            Crear Usuario
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

CreateModal.propTypes = {
  modal: PropTypes.bool,
  handleModal: PropTypes.func,
  createUser: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.shape())
};

const mapStateToProps = ({ groups }) => ({
  groups
});

const mapDispatchToProps = dispatch => ({
  createUser: user => dispatch(createNewUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateModal);
