import React from 'react';
import PropTypes from 'prop-types';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import './reports.scss';

const colors = scaleOrdinal(schemeCategory10).range();

const OperatorsChart = ({ operatorsData }) => {
  return (
    <div className="customBarChart">
      <ResponsiveContainer>
        <BarChart
          data={operatorsData}
          margin={{
            top: 40,
            right: 20,
            left: 10,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tickFormatter={label => {
              const cutLabel = label.split(' ');
              const firstName = cutLabel[0];
              return firstName;
            }}
          />
          <YAxis />
          <Tooltip
            formatter={value => {
              return [value, 'Órdenes Incompletas'];
            }}
            labelFormatter={val => `Operador: ${val}`}
          />
          <Bar dataKey="canceledTotal" barSize={50} label={{ position: 'top' }}>
            {operatorsData.map((entry, index) => (
              <Cell key={entry._id} fill={colors[index % 20]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

OperatorsChart.propTypes = {
  operatorsData: PropTypes.arrayOf(PropTypes.shape())
};

export default OperatorsChart;
