export const OPERATOR_PROFILE = ['orders_execute'];
export const AGENT_PROFILE = [
  'orders_view',
  'orders_view_company',
  'orders_create',
  'orders_assign',
  'orders_execute',
  'orders_accept',
  'orders_decline',
  'orders_edit',
  'orders_complete',
  'orders_edit_company',
  'orders_cancel',
  'orders_cancel_company',
  'orders_chat',
  'orders_archive',
  'orders_delete'
];
export const ADMIN_PROFILE = [
  'orders_create',
  'orders_view',
  'orders_view_company',
  'orders_assign',
  'orders_execute',
  'orders_accept',
  'orders_decline',
  'orders_edit',
  'orders_complete',
  'orders_edit_company',
  'orders_cancel',
  'orders_cancel_company',
  'orders_chat',
  'orders_archive',
  'orders_delete',
  'group_admin'
];
