import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Row, Container, Col, ListGroup, Form, Button } from 'react-bootstrap';
import UpImage from './image.userDetail';
import { prettyName, prettyString } from '../../utils/nameUtils';
import { getGroupList } from '../../ducks/groups';
import { getUserInfo, updateUserInfo, uploadImgProfile } from '../../ducks/user';
import { showAlert, hideAlert } from '../../ducks/alert';
import mexStates from '../../constants/mexStates';
import { OPERATOR_PROFILE, AGENT_PROFILE, ADMIN_PROFILE } from '../../constants/profiles';
import './userDetail.scss';

const colImage = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};
const UserDetail = ({
  match,
  getGroup,
  groups,
  getUserDetail,
  user,
  updateUserDetail,
  activeAlert,
  closeAlert,
  uploadImgP
}) => {
  const [onlyView, setOnlyView] = useState(true);
  const [loadingImg, setLoadingImg] = useState(false);
  const [files, setFiles] = useState([]);
  const [btnImg, setBtnImg] = useState(false);
  const [profile, setProfile] = useState(user.permissions);
  const [newGroup, setNewGroup] = useState(user.group._id);
  const [stateVal, setStateVal] = useState(null);
  const [name, setName] = useState(prettyString(user.name));
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);
  const [paternalLN, setPaternalLN] = useState(prettyString(user.paternalLastName));
  const [maternalLN, setMaternalLN] = useState(prettyString(user.maternalLastName));
  const { userId } = match.params;

  useEffect(() => {
    getGroup();
    getUserDetail(userId);
  }, []);

  useEffect(() => {
    const checkState = mexStates.filter(state => Number(state.value) === Number(user.state));
    const userState = checkState.length > 0 ? checkState[0] : null;
    setStateVal(userState);
    setName(prettyString(user.name));
    setEmail(user.email);
    setPhone(user.phone);
    setPaternalLN(prettyString(user.paternalLastName));
    setMaternalLN(prettyString(user.maternalLastName));
    setProfile(user.permissions);
  }, [user]);

  const operatorProfile = OPERATOR_PROFILE.sort((a, b) => a.localeCompare(b));
  const agentProfile = AGENT_PROFILE.sort((a, b) => a.localeCompare(b));
  const adminProfile = ADMIN_PROFILE.sort((a, b) => a.localeCompare(b));

  const filterByState = selectedOption => setStateVal(selectedOption);

  const getProfile = profileParam =>
    JSON.stringify(profileParam) === JSON.stringify(user.permissions);

  const saveChanges = () => {
    closeAlert();
    if (
      name !== '' &&
      paternalLN !== '' &&
      maternalLN !== '' &&
      email !== '' &&
      phone !== '' &&
      stateVal !== null
    ) {
      const userObj = {
        permissions: profile,
        name,
        paternalLastName: paternalLN,
        maternalLastName: maternalLN,
        email,
        phone,
        state: stateVal.value
      };
      updateUserDetail(newGroup, user._id, userObj);
      setOnlyView(true);
    } else {
      activeAlert('danger', 'Por favor complete toda la información del usuario');
    }
  };

  const handleChgImg = file => {
    setLoadingImg(true);
    uploadImgP(userId, file)
      .then(() => {
        setLoadingImg(false);
        setBtnImg(false);
      })
      .catch(() => {
        setLoadingImg(false);
      });
  };

  return (
    <Container>
      <ListGroup>
        <ListGroup.Item>
          <Row>
            <Col xs="4" md="3" lg="2" style={colImage}>
              <br />
              <img
                width="120"
                height="120"
                style={{ margin: '0 auto', borderRadius: '50%' }}
                src={user.pictureURL}
                alt="img_user"
              />
              {!onlyView && (
                <UpImage
                  files={files}
                  btnImg={btnImg}
                  setBtnImg={setBtnImg}
                  setFiles={setFiles}
                  loadingImg={loadingImg}
                  uploadImgP={file => handleChgImg(file)}
                />
              )}
            </Col>
            <Col>
              <Form>
                <Form.Group as={Row}>
                  <Form.Label column sm="8">
                    <Form.Control
                      plaintext
                      className="userTitle"
                      readOnly
                      value={prettyName(name, paternalLN, maternalLN)}
                    />
                    <span className="deactivatedText">Id del Usuario: {user._id}</span>
                  </Form.Label>
                  <Col sm="4" style={{ textAlign: 'right' }}>
                    {onlyView ? (
                      <Button
                        className="editLink"
                        variant="link"
                        onClick={() => setOnlyView(false)}
                      >
                        {'Editar '}
                        <i className="fa fa-pencil" aria-hidden="true" />
                      </Button>
                    ) : (
                      <Button
                        className="outLinePurple"
                        variant="outline-primary"
                        onClick={saveChanges}
                      >
                        Guardar
                      </Button>
                    )}
                  </Col>
                  <Col md="4" sm="12" className="inputMargBottom">
                    <Form.Label className="labelBottom">
                      <span className="subText">Nombre</span>
                    </Form.Label>
                    <Form.Control
                      disabled={onlyView}
                      value={name}
                      onChange={event => setName(event.target.value)}
                    />
                  </Col>
                  <Col md="4" sm="12">
                    <Form.Label className="labelBottom">
                      <span className="subText">Apellido Paterno</span>
                    </Form.Label>
                    <Form.Control
                      disabled={onlyView}
                      value={paternalLN}
                      onChange={event => setPaternalLN(event.target.value)}
                    />
                  </Col>
                  <Col md="4" sm="12">
                    <Form.Label className="labelBottom">
                      <span className="subText">Apellido Materno</span>
                    </Form.Label>
                    <Form.Control
                      disabled={onlyView}
                      value={maternalLN}
                      onChange={event => setMaternalLN(event.target.value)}
                    />
                  </Col>
                  <Col md="6" sm="12" className="inputMargBottom">
                    <Form.Label className="labelBottom">
                      <span className="subText">Email</span>
                    </Form.Label>
                    <Form.Control
                      disabled={onlyView}
                      value={email}
                      onChange={event => setEmail(event.target.value)}
                    />
                  </Col>
                  <Col md="6" sm="12">
                    <Form.Label className="labelBottom">
                      <span className="subText">Teléfono</span>
                    </Form.Label>
                    <Form.Control
                      disabled={onlyView}
                      value={phone}
                      onChange={event => setPhone(event.target.value)}
                    />
                  </Col>
                  <Col md="4" sm="12">
                    <Form.Label className="labelBottom">
                      <span className="subText">Grupo</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      disabled={onlyView}
                      onChange={e => setNewGroup(e.target.value)}
                    >
                      {groups.map(group => (
                        <option
                          key={group._id}
                          value={group._id}
                          selected={group._id === user.group._id}
                        >
                          {group.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col md="4" sm="12">
                    <Form.Label className="labelBottom">Estado</Form.Label>
                    <Select
                      value={stateVal}
                      onChange={filterByState}
                      options={mexStates}
                      placeholder=""
                      isDisabled={onlyView}
                    />
                  </Col>
                  <Col md="4" sm="12">
                    <Form.Label className="labelBottom">
                      <span className="subText">Perfil</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => setProfile(e.target.value.split(','))}
                      disabled={onlyView}
                    >
                      <option selected={getProfile(operatorProfile)} value={OPERATOR_PROFILE}>
                        Operador
                      </option>
                      <option selected={getProfile(agentProfile)} value={AGENT_PROFILE}>
                        Agente
                      </option>
                      <option selected={getProfile(adminProfile)} value={ADMIN_PROFILE}>
                        Administrador
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
    </Container>
  );
};

UserDetail.propTypes = {
  match: PropTypes.shape(),
  user: PropTypes.shape(),
  groups: PropTypes.arrayOf(PropTypes.shape()),
  getGroup: PropTypes.func,
  getUserDetail: PropTypes.func,
  activeAlert: PropTypes.func,
  updateUserDetail: PropTypes.func,
  closeAlert: PropTypes.func,
  uploadImgP: PropTypes.func
};

const mapStateToProps = ({ groups, user }) => ({
  groups,
  user: user.userDetail
});

const mapDispatchToProps = dispatch => ({
  getGroup: () => dispatch(getGroupList()),
  getUserDetail: userId => dispatch(getUserInfo(userId)),
  updateUserDetail: (userId, userData, groupId) =>
    dispatch(updateUserInfo(userId, userData, groupId)),
  activeAlert: (kind, message) => dispatch(showAlert(kind, message)),
  closeAlert: () => dispatch(hideAlert()),
  uploadImgP: (userId, file) => dispatch(uploadImgProfile(userId, file))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
