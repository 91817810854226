import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Navbar, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import api from '../../../api/api';
import { setUser, setLogOut } from '../../../ducks/user';
import './header.scss';

const Header = ({ userName, isLoggedIn, setUserInfo, logOut }) => {
  useEffect(() => {
    const user = localStorage.getItem('user');
    if (userName === '' && user) {
      setUserInfo(JSON.parse(user));
    }
  }, []);
  return (
    <Navbar className="header" bg="light" expand="lg">
      <Link to={isLoggedIn ? '/home' : '/login'}>
        <Navbar.Brand>
          <img
            src="/assets/img/logo_konfio.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
          <span className="headerTitle">Konfio</span>
        </Navbar.Brand>
      </Link>
      {isLoggedIn && (
        <Link to="/users">
          <span className="headerLink">Usuarios</span>
        </Link>
      )}
      {isLoggedIn && (
        <Link to="/orderList">
          <span className="headerLink">Ordenes</span>
        </Link>
      )}
      {isLoggedIn && (
        <Link to="/reports">
          <span className="headerLink">Reportes</span>
        </Link>
      )}
      {isLoggedIn && (
        <Navbar.Collapse data-testid="userLogged" className="justify-content-end">
          <Navbar.Text>Hola {userName}!</Navbar.Text>
          <Nav.Link
            onClick={() => {
              logOut();
              api.logOut();
            }}
          >
            <i className="fa fa-sign-out" aria-hidden="true" />
            Salir
          </Nav.Link>
        </Navbar.Collapse>
      )}
    </Navbar>
  );
};

Header.propTypes = {
  userName: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  setUserInfo: PropTypes.func,
  logOut: PropTypes.func
};

const mapStateToProps = ({ user }) => ({
  userName: user.userName,
  isLoggedIn: user.isLoggedIn
});

const mapDispatchToProps = dispatch => ({
  setUserInfo: user => dispatch(setUser(user)),
  logOut: () => dispatch(setLogOut())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
