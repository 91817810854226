import { reports as initialState } from '../config/initialState.json';
import {
  getOrdersByWeekDay,
  getOrdersByStatus,
  getOrdersByOperators,
  getCanceledOrders,
  getAvgTime
} from '../api/services/report';
import { showLoader, hideLoader } from './loader';
import errorHandler from '../utils/errorHandler';
import { showAlert } from './alert';
import { utcDateZero } from '../utils/calendarUtils';
import { prettyName } from '../utils/nameUtils';

const SET_ORDERS_BY_WEEK_DAY = 'SET_ORDERS_BY_WEEK_DAY';
const SET_ORDER_BY_STATUS = 'SET_ORDER_BY_STATUS';
const SET_OPERATORS_DATA = 'SET_OPERATORS_DATA';
const SET_CANCELED_ORDERS = 'SET_CANCELED_ORDERS';
const SET_AVERAGE_TIME = 'SET_AVERAGE_TIME';

export const setOrdersbyWeekDay = ordersByDayOfWeek => ({
  type: SET_ORDERS_BY_WEEK_DAY,
  ordersByDayOfWeek
});

export const setOrderByStatus = ordersByStatus => ({
  type: SET_ORDER_BY_STATUS,
  ordersByStatus
});

export const setOperatorsData = operatorsData => ({
  type: SET_OPERATORS_DATA,
  operatorsData
});

export const setCanceledOrders = rescheduledOrders => ({
  type: SET_CANCELED_ORDERS,
  rescheduledOrders
});

export const setAverageTime = averageTime => ({
  type: SET_AVERAGE_TIME,
  averageTime
});

export const listOrdersByWeekDay = (startDate, endDate, state) => dispatch => {
  dispatch(showLoader());
  getOrdersByWeekDay(utcDateZero(startDate), utcDateZero(endDate), state)
    .then(response => {
      const addChartIndex = weekDay =>
        weekDay.map(day => ({
          ...day,
          index: 1
        }));
      const sunday = addChartIndex(response.sunday);
      const monday = addChartIndex(response.monday);
      const tuesday = addChartIndex(response.tuesday);
      const wendsday = addChartIndex(response.wendsday);
      const thursday = addChartIndex(response.thursday);
      const friday = addChartIndex(response.friday);
      const saturday = addChartIndex(response.saturday);
      dispatch(
        setOrdersbyWeekDay({
          sunday,
          monday,
          tuesday,
          wendsday,
          thursday,
          friday,
          saturday
        })
      );
      dispatch(hideLoader());
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

export const getStatusOrders = (startDate, endDate, state) => dispatch => {
  dispatch(showLoader());
  getOrdersByStatus(utcDateZero(startDate), utcDateZero(endDate), state)
    .then(response => {
      dispatch(setOrderByStatus(response));
      dispatch(hideLoader());
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

export const getOperatorsData = (startDate, endDate, state) => dispatch => {
  dispatch(showLoader());
  getOrdersByOperators(utcDateZero(startDate), utcDateZero(endDate), state)
    .then(response => {
      dispatch(hideLoader());
      const formatOperators = response.map(operator => ({
        _id: operator._id,
        name: prettyName(operator.name, operator.paternalLastName, operator.maternalLastName),
        canceledTotal: operator.canceledTotal
      }));
      dispatch(setOperatorsData(formatOperators));
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

export const getOrdersCanceled = (startDate, endDate, state) => dispatch => {
  dispatch(showLoader());
  getCanceledOrders(utcDateZero(startDate), utcDateZero(endDate), state)
    .then(response => {
      const colors = [
        '#e74c3c',
        '#e67e22',
        '#c0392b',
        '#f39c12',
        '#d35400',
        '#f1c40f',
        '#bdc3c7',
        '#95a5a6'
      ];
      const rescheduledOrders = response.map((order, index) => ({
        name: `Status: ${order.status}`,
        value: order.total,
        color: colors[index]
      }));
      dispatch(setCanceledOrders(rescheduledOrders));
      dispatch(hideLoader());
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

export const getAverageTime = (startDate, endDate, state) => dispatch => {
  dispatch(showLoader());
  getAvgTime(utcDateZero(startDate), utcDateZero(endDate), state)
    .then(response => {
      const averageTime = response;
      averageTime.AvgTotal = response.AvgStartToArrive + response.AvgArriveToCollect;
      dispatch(setAverageTime(averageTime));
      dispatch(hideLoader());
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERS_BY_WEEK_DAY:
      return {
        ...state,
        ordersByDayOfWeek: action.ordersByDayOfWeek
      };
    case SET_ORDER_BY_STATUS:
      return {
        ...state,
        ordersByStatus: action.ordersByStatus
      };
    case SET_OPERATORS_DATA:
      return {
        ...state,
        operatorsData: action.operatorsData
      };
    case SET_CANCELED_ORDERS: {
      return {
        ...state,
        canceledOrders: action.rescheduledOrders
      };
    }
    case SET_AVERAGE_TIME: {
      return {
        ...state,
        averageTime: action.averageTime
      };
    }
    default:
      return state;
  }
};
