import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PropTypes } from 'prop-types';

const PrivateRoute = () => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
  const { isLoggedIn } = user;
  return (
    <Route
      path="/"
      render={props =>
        isLoggedIn ? (
          <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  location: PropTypes.shape({})
};

export default PrivateRoute;
