import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import mainReducer from './mainReducer';

const middlewares = [thunkMiddleware, createLogger()];

let getStore = createStore(mainReducer, compose(applyMiddleware(thunkMiddleware)));

if (process.env.NODE_ENV === 'development') {
  getStore = createStore(mainReducer, composeWithDevTools(applyMiddleware(...middlewares)));
}

const store = getStore;

export default store;
