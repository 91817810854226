import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Board from './components/board/index.board';
import Login from './components/login/login';
import OrderDetail from './components/orderDetail/index.orderDetail';
import UserList from './components/userList/index.userList';
import UserDetail from './components/userDetail/index.userDetail';
import OrderList from './components/orderList/index.orderList';
import PrivateRoute from './components/common/privateRoute';
import Header from './components/common/header/index.header';
import Loader from './components/common/loader/index.loader';
import Alert from './components/common/alert/index.alert';
import Reports from './components/reports/index.reports';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div>
      <Loader />
      <Header />
      <Alert />
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/orderDetail/:orderId" component={OrderDetail} />
        <Route path="/userDetail/:userId" component={UserDetail} />
        <Route path="/users" component={UserList} />
        <Route path="/reports" component={Reports} />
        <Route
          path="/orderList/:startDt?/:endDt?/:stateParam?/:statusParam?/:groupParam?"
          component={OrderList}
        />
        <Route path="/home" component={Board} />
        <PrivateRoute />
      </Switch>
    </div>
  );
}

export default App;
