import { alert as initialState } from '../config/initialState.json';

const SHOW_ALERT = 'SHOW_ALERT';
const HIDE_ALERT = 'HIDE_ALERT';

export const showAlert = (kind, message) => ({ type: SHOW_ALERT, kind, message });
export const hideAlert = () => ({ type: HIDE_ALERT });

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        showAlert: true,
        message: action.message,
        kind: action.kind
      };
    case HIDE_ALERT:
      return {
        showAlert: false,
        message: '',
        kind: ''
      };
    default:
      return state;
  }
};
