import { create } from 'apisauce';
import history from '../utils/history';

// Endpoint
export const ordersEndpoint =
  process.env.REACT_APP_ENDPOINT_ENV === 'production'
    ? 'https://api.heyastro.net/prometheus'
    : 'https://api.dev.heyastro.net/prometheus';

console.log('process.env.REACT_APP_ENDPOINT_ENV', process.env.REACT_APP_ENDPOINT_ENV);

const api = create({
  baseURL: ordersEndpoint
});

api.logOut = () => {
  localStorage.clear();
  history.push('/login');
};

api.addResponseTransform(response => {
  if (response.status >= 400 || !response.ok) {
    const error = new Error(response.status || response.problem);

    error.status = response.status;
    error.response = response;
    if (response.status === 401 || response.status === null) {
      api.logOut();
    }

    throw response;
  }
});

if (localStorage.getItem('token')) {
  const token = localStorage.getItem('token');
  api.setHeader('x-astro-token', token);
}

export default api;
