import { groups as initialState } from '../config/initialState.json';
import { getGroups } from '../api/services/group';
import { showLoader, hideLoader } from './loader';
import { showAlert } from './alert';
import errorHandler from '../utils/errorHandler';

// ACTIONS
export const SET_GROUPS = 'SET_GROUPS';

export const setGroup = groups => ({ type: SET_GROUPS, groups });

export const getGroupList = () => dispatch => {
  dispatch(showLoader());
  getGroups()
    .then(response => {
      dispatch(setGroup(response));
      dispatch(hideLoader());
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

// REDUCER
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUPS:
      return action.groups;
    default:
      return state;
  }
};
