import { loanInfo as initialState } from '../config/initialState.json';
import { getLoanInfo } from '../api/services/order';
import { showLoader, hideLoader } from './loader';
import { showAlert } from './alert';
import errorHandler from '../utils/errorHandler';

// ACTIONS
export const SET_LOAN_INFO = 'SET_LOAN_INFO';

export const setLoanInfo = loanInfo => ({ type: SET_LOAN_INFO, loanInfo });

export const getUniqueLoanInfo = orderId => dispatch => {
    dispatch(showLoader());
    getLoanInfo(orderId)
      .then(response => {
        dispatch(setLoanInfo(response));
        dispatch(hideLoader());
    })
      .catch(error => {
        dispatch(hideLoader());
        dispatch(showAlert('danger', errorHandler(error)));
    });
};

// REDUCER
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOAN_INFO:
      return action.loanInfo;
    default:
      return state;
  }
};
