import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { statusTranslatorSimplified } from '../../utils/statusUtils';
import { prettyName } from '../../utils/nameUtils';
import { prettyDate } from '../../utils/calendarUtils';
import './board.scss';

const CardBoard = ({ order }) => (
  <Link data-testid="cardLink" className="link" to={`/orderDetail/${order._id}`}>
    <Card className="card">
      <Card.Body>
        <div className="inlineElements">
          {order.group && <Card.Title className="cardMargHeader">{order.group.name}</Card.Title>}
          <p className="cardMargHeader statusText">
            {statusTranslatorSimplified(order.status).status}
            <span
              className="statusBall"
              style={{
                backgroundColor: statusTranslatorSimplified(order.status).color
              }}
            />
          </p>
        </div>
        <Card.Subtitle className="mb-2 text-muted">{order.service.name}</Card.Subtitle>
        <Card.Text>
          <strong>Folio:</strong> {order.contractNumber}
          <br />
          <strong>Dirección:</strong> {order.location.name}
          <br />
          {order.executor && (
            <span>
              <strong>Operador:</strong>{' '}
              {prettyName(
                order.executor.name,
                order.executor.paternalLastName,
                order.executor.maternalLastName
              )}
              <br />
            </span>
          )}
          {order.client && (
            <span>
              <strong>Cliente:</strong>{' '}
              {prettyName(
                order.client.name,
                order.client.paternalLastName,
                order.client.maternalLastName
              )}
              <br />
            </span>
          )}
        </Card.Text>
      </Card.Body>
      <div className="cardSeparator" />
      <div className="inlineElements">
        <p className="cardFooterText">
          <i className="fa fa-calendar" aria-hidden="true" />
          {` ${prettyDate(order.scheduledDate)}`}
        </p>
        {/* <Card.Link className="cardLink" href="#">Asignar</Card.Link> */}
      </div>
    </Card>
  </Link>
);

CardBoard.propTypes = {
  order: PropTypes.shape()
};

export default CardBoard;
