import React from 'react';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { PropTypes } from 'prop-types';
import { statusTimeLine } from '../../utils/statusUtils';
import { prettyDate } from '../../utils/calendarUtils';
import { prettyName } from '../../utils/nameUtils';
import './orderDetail.scss';

const OrderData = ({ status, scheduledDate, operator }) => {
  const timeLineData = statusTimeLine(status);

  const getTimeLineText = () => {
    const timeLineObj = {
      operator: {
        name: 'No se ha asignado aún',
        phone: ''
      },
      onTheWayText: 'No se ha empezado la orden',
      withClientText: 'No ha llegado con el cliente',
      withEvidenceText: 'No se ha recolectado toda la evidencia',
      completedText: 'No se ha completado la visita'
    };
    const { name, paternalLastName, maternalLastName, phone } = operator;
    if (timeLineData.assigned.showText) {
      timeLineObj.operator.name = `Asignado a ${prettyName(
        name,
        paternalLastName,
        maternalLastName
      )}`;
      timeLineObj.operator.phone = `cel: ${phone}`;
    }
    if (timeLineData.onTheWay.showText) {
      timeLineObj.onTheWayText = 'El operador va en camino';
    }
    if (timeLineData.arrived.showText) {
      timeLineObj.withClientText = 'El operador llego con el cliente';
    }
    if (timeLineData.tookEvidence.showText) {
      timeLineObj.withEvidenceText = 'El operador tomo las evidencias';
    }
    if (timeLineData.completed.showText) {
      timeLineObj.completedText = 'El operador completo la visita';
    }

    return timeLineObj;
  };

  return (
    <Timeline style={{ position: 'relative', left: '5%' }} lineColor="#825CA4">
      <TimelineEvent
        createdAt="Orden agendada"
        title={timeLineData.created.showText && prettyDate(scheduledDate)}
        iconColor={timeLineData.created.color}
        bubbleStyle={{ backgroundColor: timeLineData.created.showText && '#fff' }}
        style={{ marginBottom: '17%', marginTop: '17%' }}
        icon={<i className="fa fa-calendar" aria-hidden="true" />}
      />
      <TimelineEvent
        createdAt={getTimeLineText().operator.name}
        title={getTimeLineText().operator.phone}
        style={{ marginBottom: '17%' }}
        iconColor={timeLineData.assigned.color}
        icon={<i className="fa fa-user" aria-hidden="true" />}
      />
      <TimelineEvent
        title={getTimeLineText().onTheWayText}
        createdAt=""
        style={{ marginBottom: '17%' }}
        iconColor={timeLineData.onTheWay.color}
        icon={<i className="fa fa-motorcycle" aria-hidden="true" />}
      />
      <TimelineEvent
        title={getTimeLineText().withClientText}
        createdAt=""
        style={{ marginBottom: '17%' }}
        iconColor={timeLineData.arrived.color}
        icon={<i className="fa fa-map-marker" aria-hidden="true" />}
      />
      <TimelineEvent
        title={getTimeLineText().withEvidenceText}
        createdAt=""
        style={{ marginBottom: '17%' }}
        iconColor={timeLineData.tookEvidence.color}
        icon={<i className="fa fa-picture-o" aria-hidden="true" />}
      />
      <TimelineEvent
        title={getTimeLineText().completedText}
        createdAt=""
        style={{ marginBottom: '17%' }}
        iconColor={timeLineData.completed.color}
        icon={<i className="fa fa-check" aria-hidden="true" />}
      />
    </Timeline>
  );
};

OrderData.propTypes = {
  status: PropTypes.number,
  scheduledDate: PropTypes.string,
  operator: PropTypes.shape()
};

export default OrderData;
