const SERVICES = [
  {
    value: '5defb7bf42ef7f50d52ee200',
    label: 'Visita a Cliente'
  },
  {
    value: '5dfcf0e6a903e33a5bc367f0',
    label: 'Visita KYC PFAE'
  },
  {
    value: '5dfcf0f9defdde343feeef24',
    label: 'Visita KYC PM',
    description: 'Visita de verificación a persona moral'
  },
  {
    value: '5dfcf11c10ca8d6ae2d1ff10',
    label: 'Recolección de Contrato PFAE'
  },
  {
    value: '5dfcf13ea903e31230c367f1',
    label: 'Verificación de Domicilio PFAE'
  },
  {
    value: '5e18ffe2e4d8503cdbac6e17',
    label: 'Recolección de Contrato PM'
  },
  {
    value: '5e190008e4d8503cdbac6e18',
    label: 'Verificación de Domicilio PM'
  },
  {
    value: '5ed04dc02ffcdf977a20d13b',
    label: 'Visita Ocular PFAE',
  },
  {
    value: '5ed04ecc2ffcdfd6e320d13e',
    label: 'Visita Ocular PM',
  },
  {
    value: '5ed04de42ffcdf5ae820d13c',
    label: 'Visita Informativa PFAE',
  },
  {
    value: '5ed04eb02ffcdf5c7620d13d',
    label: 'Visita Informativa PM',
  },

];

export default SERVICES;
