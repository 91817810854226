import api from '../api';

export const getOrdersList = (
  startDate,
  endDate,
  page = 0,
  startStatus = 0,
  endStatus = 899,
  state,
  group,
  inverseOrder = false,
  limit = 9999
) =>
  new Promise((resolve, reject) => {
    const paramsObj = {
      endDate,
      endStatus,
      startDate,
      startStatus,
      limit,
      page
    };
    if (state) {
      paramsObj.key = 'location.state';
      paramsObj.value = state;
    }

    if (group && group !== 'undefined') {
      paramsObj.group = group;
    }
    if (inverseOrder) {
      paramsObj.sort = '-scheduledDate';
    }
    api
      .get('/orders', paramsObj)
      .then(response => {
        return resolve(response.data);
      })
      .catch(error => {
        if (error.data === null) {
          error.data = {
            message: null
          };
        }
        reject(error.data);
      });
  });

export const getOrderDetail = id =>
  new Promise((resolve, reject) => {
    api
      .get(`orders/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => reject(error.data));
  });

export const getLoanInfo = id =>
  new Promise((resolve, reject) => {
    api
      .get(`orders/${id}/loan-info`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => reject(error.data));
  });

export const editOrderGroup = (orderId, groupId) =>
  new Promise((resolve, reject) => {
    api
      .patch(`/orders/${orderId}/add-group`, {
        groupId
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => reject(error.data));
  });

export const editOperator = (orderId, userId) =>
  new Promise((resolve, reject) => {
    api
      .patch(`/orders/${orderId}/add-operator`, {
        userId
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => reject(error.data));
  });

export const editOrderLocation = (orderId, location) =>
  new Promise((resolve, reject) => {
    api
      .patch(`/orders/${orderId}/location`, location)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => reject(error.data));
  });

export const editOrderService = (orderId, serviceId) =>
  new Promise((resolve, reject) => {
    api
      .patch(`/orders/${orderId}/service`, {
        serviceId
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => reject(error.data));
  });
