import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ error, dataTestId }) => (
  <div className="errorText" data-testid={dataTestId}>
    {error}
  </div>
);

ErrorMessage.propTypes = {
  error: PropTypes.string,
  dataTestId: PropTypes.string
};

export default ErrorMessage;
