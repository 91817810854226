import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'is-empty';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

const dropZone = {
  position: 'absolute',
  top: 0,
  left: '1%',
  width: '99%',
  height: '55%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  border: '1px dashed #666',
  borderRadius: '18px',
  fontSize: 12,
  zIndex: 4
};

const inputDropZone = {
  position: 'relative',
  marginTop: 30,
  fontSize: 12
};
const thumbsContainer = {
  position: 'absolute',
  top: 0,
  left: '1%',
  width: '99%',
  height: '55%'
};

const UpImage = ({ files, btnImg, setBtnImg, setFiles, loadingImg, uploadImgP }) => {
  const [urlFile, setUrlFile] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*'],
    onDrop: acceptedFiles => {
      const imageUrl = URL.createObjectURL(acceptedFiles[0]);
      setFiles([...acceptedFiles]);
      setBtnImg(true);
      setUrlFile(imageUrl);
    }
  });

  return (
    <section>
      <div style={dropZone} {...getRootProps()}>
        <p style={{ color: '#825CA4', margin: 0 }}>Arrastra aquí</p>
      </div>

      <div style={inputDropZone} {...getRootProps()}>
        <input {...getInputProps()} />
        <p style={{ cursor: 'pointer', color: '#825CA4' }}>Cargar desde mis archivos</p>
      </div>

      {btnImg && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Button
            className="outLinePurple"
            variant="outline-primary"
            onClick={() => {
              uploadImgP(...files);
            }}
          >
            {loadingImg ? 'Cargando...' : 'Cambiar Imagen'}
          </Button>
          <Button
            className="editLink"
            variant="link"
            style={{ color: '#666', marginTop: 10 }}
            onClick={() => {
              setFiles([]);
              setUrlFile(null);
            }}
          >
            Cancelar
          </Button>
        </div>
      )}
      {!isEmpty(files) && (
        <aside
          style={{
            ...thumbsContainer,
            backgroundColor: !isEmpty(files) ? '#fff' : 'transparent'
          }}
        >
          <img
            width="120"
            height="120"
            style={{ borderRadius: '50%' }}
            src={urlFile}
            className="imgThumb"
            alt="img_loaded"
          />
        </aside>
      )}
    </section>
  );
};

UpImage.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  btnImg: PropTypes.bool,
  setBtnImg: PropTypes.func,
  setFiles: PropTypes.func,
  loadingImg: PropTypes.bool,
  uploadImgP: PropTypes.func
};

export default UpImage;
