export function prettyString(string) {
  try {
    const lcString = string.toLowerCase();
    return lcString.charAt(0).toUpperCase() + lcString.slice(1);
  } catch (err) {
    return string;
  }
}

export function prettyName(fn, ln, mln) {
  if (prettyString(fn) && prettyString(ln) && prettyString(mln)) {
    return `${prettyString(fn)} ${prettyString(ln)} ${prettyString(mln)}`;
  }
  return '';
}
