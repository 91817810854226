import api from '../api';

export const getOrdersByWeekDay = (startDate, endDate, state) =>
  new Promise((resolve, reject) => {
    api
      .get('/reports/dayweek-orders', {
        startDate,
        endDate,
        state
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => reject(error.data));
  });

export const getOrdersByStatus = (startDate, endDate, state) =>
  new Promise((resolve, reject) => {
    api
      .get('/reports/status-orders', {
        startDate,
        endDate,
        state
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => reject(error.data));
  });

export const getCanceledOrders = (startDate, endDate, state) =>
  new Promise((resolve, reject) => {
    api
      .get('/reports/cancel-orders', {
        startDate,
        endDate,
        state
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => reject(error.data));
  });

export const getOrdersByOperators = (startDate, endDate, state) =>
  new Promise((resolve, reject) => {
    api
      .get('/reports/operators-orders', {
        startDate,
        endDate,
        state
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => reject(error.data));
  });

export const getAvgTime = (startDate, endDate, state) =>
  new Promise((resolve, reject) => {
    api
      .get('/reports/average-time', {
        startDate,
        endDate,
        state
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => reject(error.data));
  });
