import moment from 'moment';
import timezone from 'moment-timezone';
import 'moment/locale/es';

moment.locale('es');

export const utcDate = (date = moment()) =>
  moment(date)
    .utc()
    .format();
export const utcDateZero = (date = moment()) =>
  moment(date)
    .utc()
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format();

export const addDate = (date = moment(), lapse, dimension) =>
  moment(date)
    .add(dimension, lapse)
    .set({ minute: 0 })
    .utc()
    .format();

export const subtractDate = (date = moment(), lapse, dimension) =>
  moment(date)
    .subtract(lapse, dimension)
    .set({ minute: 0 })
    .utc()
    .format();

export const setInitialTime = momentDate =>
  momentDate
    .utc()
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format();

export const setFinalTime = momentDate =>
  momentDate
    .utc()
    .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
    .format();

export const prettyDate = date => {
  const userTz = timezone.tz.guess();
  if (date !== '') {
    return timezone.tz(date, userTz).format('DD MMM, hh:mm A');
  }
  return '';
};

export const secondsToHHmm = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds / 60 - hours * 60);
  return `${hours}h ${minutes}m`;
};
