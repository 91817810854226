import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Container, Col, ListGroup, Form, Button } from 'react-bootstrap';
import { getUserList } from '../../ducks/user';
import { getGroupList } from '../../ducks/groups';
import { prettyName } from '../../utils/nameUtils';
import './userList.scss';

const List = ({ getListUser, userList, getGroups, groups, modal, handleModal }) => {
  useEffect(() => {
    getListUser();
    getGroups();
  }, []);

  const [users, setUsers] = useState(userList);
  const [groupId, setGroupId] = useState(null);

  const [page, setPage] = useState(0);

  useEffect(() => {
    if (users !== userList) {
      setUsers(userList);
    }
  }, [userList]);

  useEffect(() => {
    getListUser(page, groupId);
  }, [page, groupId]);

  const filterByName = event => {
    const text = event.target.value;
    const tempUsers = userList.filter(
      user =>
        prettyName(user.name, user.paternalLastName, user.maternalLastName)
          .toLowerCase()
          .indexOf(text.toLowerCase()) !== -1
    );
    setUsers(tempUsers);
  };

  const filterByGroup = event => {
    const groupVal = event.target.value;
    setGroupId(groupVal);
  };

  return (
    <Container className="userListCont">
      <Form>
        <Row>
          <Col md="5">
            <Form.Group>
              <Form.Label>Buscar Usuario</Form.Label>
              <Form.Control data-testid="userNameInput" onChange={filterByName} />
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group controlId="formGridState">
              <Form.Label>Grupo</Form.Label>
              <Form.Control as="select" onChange={filterByGroup} data-testid="groupsSelect">
                <option value="">Todos</option>
                {groups.map(group => (
                  <option key={group._id} value={group._id}>
                    {group.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col className="purpleBtnCont">
            <Button className="purpleBtn" block onClick={() => handleModal(!modal)}>
              <i className="fa fa-user-plus" aria-hidden="true" />
              {' Crear'}
            </Button>
          </Col>
        </Row>
      </Form>
      <ListGroup data-testid="users">
        {users.map(user => (
          <ListGroup.Item key={user._id}>
            <Row>
              <Col xs="2" md="1">
                <img
                  alt=""
                  src={user.pictureURL}
                  width="60"
                  height="60"
                  style={{ borderRadius: '50%' }}
                />
              </Col>
              <Col>
                <Row>
                  <Col>
                    <h4>{prettyName(user.name, user.paternalLastName, user.maternalLastName)}</h4>
                  </Col>
                  <Col className="linkRight">
                    <Link to={`/userDetail/${user._id}`}>Ver detalle</Link>
                  </Col>
                </Row>
                correo: {user.email}
                <br />
                Cel: {user.phone}
                <br />
                Grupo: {user.group.name}
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <div className="pageCont">
        {page > 0 && (
          <i
            className="fa fa-chevron-left arrowLeft"
            aria-hidden="true"
            data-testid="lessPage"
            onClick={() => setPage(page - 1)}
          />
        )}
        {page + 1}
        {userList.length >= 50 && (
          <i
            className="fa fa-chevron-right arrowRight"
            aria-hidden="true"
            data-testid="plusPage"
            onClick={() => setPage(page + 1)}
          />
        )}
      </div>
    </Container>
  );
};

List.propTypes = {
  getListUser: PropTypes.func,
  getGroups: PropTypes.func,
  userList: PropTypes.arrayOf(PropTypes.shape()),
  groups: PropTypes.arrayOf(PropTypes.shape()),
  modal: PropTypes.bool,
  handleModal: PropTypes.func
};

const mapStateToProps = ({ user, groups }) => ({
  userList: user.userList,
  groups
});

const mapDispatchToProps = dispatch => ({
  getListUser: (page, idGroup) => dispatch(getUserList(page, idGroup)),
  getGroups: () => dispatch(getGroupList())
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
