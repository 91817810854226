import React, { useState } from 'react';
import List from './list.userList';
import CreateModal from './createUser.userList';

const UserList = () => {
  const [modal, handleModal] = useState(false);

  return (
    <div>
      <List modal={modal} handleModal={handleModal} />
      <CreateModal modal={modal} handleModal={handleModal} />
    </div>
  );
};

export default UserList;
