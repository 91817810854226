const API_ERRORS = {
  ADMIN_NOT_FOUND: 'AdminNotFound',
  INCORRECT_PASSWORD: 'IncorrectPassword',
  USER_DUPLICATE_ERROR: 'UserDuplicateError',
  DUPLICATE_ERROR: 'DuplicateError',
  SESSION_EXPIRED: 'SessionExpired',
  SESSION_ERROR: 'UnknownSessionError'
};

export default API_ERRORS;
