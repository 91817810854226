import { useState } from 'react';
import moment from 'moment';

const useDateRange = () => {
  const defaultStartDate = moment().subtract(30, 'days');
  const defaultEndDate = moment();
  const [focusInput, setFocusInput] = useState(null);
  const [dateStart, setDateStart] = useState(defaultStartDate);
  const [dateEnd, setDateEnd] = useState(defaultEndDate);
  const [page, setPage] = useState(0);

  const filterByDate = ({ startDate, endDate }) => {
    setPage(0);
    setDateStart(moment(startDate));
    setDateEnd(moment(endDate));
  };

  return {
    focusInput,
    setFocusInput,
    dateStart,
    dateEnd,
    page,
    setPage,
    filterByDate
  };
};

export default useDateRange;
