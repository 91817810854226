import { user as initialState } from '../config/initialState.json';

import {
  loginUser,
  listUsers,
  createUser,
  updateUserGroup,
  editUser,
  getUserDetail,
  uploadFileUrl
} from '../api/services/user';
import { showLoader, hideLoader } from './loader';
import { prettyName } from '../utils/nameUtils';
import history from '../utils/history';
import { showAlert, hideAlert } from './alert';
import errorHandler from '../utils/errorHandler';

// ACTIONS
export const SET_USER = 'SET_USER';
export const SET_LOGOUT = 'SET_LOGOUT';
export const SET_LIST_USERS = 'SET_LIST_USERS';
export const ADD_USER = 'ADD_USER';
export const SET_USER_DETAIL = 'SET_USER_DETAIL';
export const SET_USER_PIC = 'SET_USER_PIC';

export const setUser = user => ({ type: SET_USER, user });
export const setLogOut = () => ({ type: SET_LOGOUT });
export const setUserList = userList => ({ type: SET_LIST_USERS, userList });
export const addUser = user => ({ type: ADD_USER, user });
export const setUserDetail = userDetail => ({ type: SET_USER_DETAIL, userDetail });
export const setUserPic = pictureURL => ({ type: SET_USER_PIC, pictureURL });

export const doLogin = (email, password) => dispatch => {
  dispatch(showLoader());
  dispatch(hideAlert());
  loginUser(email, password)
    .then(response => {
      const userName = prettyName(
        response.name,
        response.paternalLastName,
        response.maternalLastName
      );
      const user = {
        _id: response._id,
        userName,
        email: response.email,
        isLoggedIn: true
      };

      localStorage.setItem('user', JSON.stringify(user));
      dispatch(setUser(user));
      dispatch(hideLoader());
      history.push('/home');
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

export const getUserList = (page, groupId) => dispatch => {
  dispatch(showLoader());
  listUsers(50, page, groupId)
    .then(response => {
      dispatch(setUserList(response));
      dispatch(hideLoader());
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

export const createNewUser = user => dispatch => {
  dispatch(showLoader());
  createUser(user)
    .then(response => {
      dispatch(addUser(response));
      dispatch(hideLoader());
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

export const getUserInfo = userId => dispatch => {
  dispatch(showLoader());
  getUserDetail(userId)
    .then(response => {
      const userData = response;
      userData.permissions.sort((a, b) => a.localeCompare(b));
      dispatch(setUserDetail(userData));
      dispatch(hideLoader());
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

export const updateUserInfo = (groupId, userId, userData) => dispatch => {
  const requests = groupId
    ? [updateUserGroup(userId, groupId), editUser(userId, userData)]
    : [editUser(userId, userData)];
  Promise.all(requests)
    .then(responses => {
      const tempUserDetail = responses[0];
      if (responses.length > 1) {
        tempUserDetail.permissions = responses[1].permissions;
      }
      dispatch(setUserDetail(tempUserDetail));
    })
    .catch(error => {
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

export const uploadImgProfile = (userId, file) => dispatch => {
  return new Promise((res, rej) => {
    const fileName = `${userId}.jpg`;
    uploadFileUrl(userId, fileName, file)
      .then(uploadedFile => {
        res(uploadedFile);
      })
      .catch(err => {
        dispatch(showAlert('danger', errorHandler(err)));
        rej(err);
      });
  });
};

export const logOut = () => dispatch => dispatch(setLogOut());

// REDUCER
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.user
      };
    case ADD_USER:
      return {
        ...state,
        userList: [action.user, ...state.userList]
      };
    case SET_LIST_USERS:
      return {
        ...state,
        userList: action.userList
      };
    case SET_USER_DETAIL:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          ...action.userDetail
        }
      };
    case SET_USER_PIC:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          pictureURL: action.pictureURL
        }
      };
    case SET_LOGOUT:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
