import React from 'react';
import { PropTypes } from 'prop-types';
import { Row, Col, ButtonToolbar } from 'react-bootstrap';
import ERROR_MESSAGES from '../../constants/errorMessages';
import './orderDetail.scss';

const Visit = ({ instructions, location, evidences, documents, interior }) => {
  const signatures = evidences.filter(
    evidence => evidence.type === 'signature' || evidence.type === 'id_picture'
  );
  const businessPictures = evidences.filter(
    evidence => evidence.type === 'location_picture' || evidence.type === 'address_picture'
  );
  const contractPictures = evidences.filter(evidence => evidence.type === 'contract_picture');

  return (
    <div>
      <div className="detailSpace">
        <h5>Instrucciones de la visita</h5>
        {instructions}
      </div>
      <hr />
      <div className="detailSpace">
        <h5>Lugar de la visita</h5>
        {location}
        <br />
        <strong>Interior:</strong> {interior}
      </div>
      <hr />
      <div className="detailSpace">
        <h5>Firma del Cliente</h5>
        {signatures.length > 0
          ? signatures.map(signature => (
              <img
                src={signature.url}
                width="300"
                key={signature._id}
                height="100"
                className="d-inline-block align-top"
                alt="No se pudo cargar la firma o id"
              />
            ))
          : ERROR_MESSAGES.NO_SIGNATURE}
      </div>
      <hr />
      <div className="detailSpace">
        <h5>Fotos del Negocio</h5>
        <Row>
          {businessPictures.length > 0 ? (
            businessPictures.map(businessPicture => (
              <Col xs="6" md="3" className="pictureMargin" key={businessPicture._id}>
                <a href={businessPicture.url} rel="noopener noreferrer" target="_blank">
                  <img
                    src={businessPicture.url}
                    width="80"
                    height="80"
                    target="_blank"
                    className="d-inline-block align-top"
                    alt="No se pudo cargar la imagen del negocio"
                  />
                </a>
              </Col>
            ))
          ) : (
            <Col>{ERROR_MESSAGES.NO_BUSINESS_PICTURES}</Col>
          )}
        </Row>
      </div>
      <hr />
      <div className="detailSpace">
        <h5>Fotos del Contrato</h5>
        <Row>
          {contractPictures.length > 0 ? (
            contractPictures.map(contractPicture => (
              <Col xs="6" md="3" className="pictureMargin" key={contractPicture._id}>
                <a href={contractPicture.url} rel="noopener noreferrer" target="_blank">
                  <img
                    src={contractPicture.url}
                    width="80"
                    height="80"
                    className="d-inline-block align-top"
                    alt="No se pudo cargar la imagen del contrato"
                  />
                </a>
              </Col>
            ))
          ) : (
            <Col>{ERROR_MESSAGES.NO_CONTRACT_PICTURES}</Col>
          )}
        </Row>
        <hr />
        <div className="detailSpace">
          <h5>Encuesta</h5>
          <ButtonToolbar>
            {documents.length > 0
              ? documents.map(doc => {
                  const file = doc.url;
                  const isPDF = /.pdf/.test(file);
                  if (isPDF) {
                    return (
                      <a
                        className="pdfBtn"
                        href={doc.url}
                        key={doc._id}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {'Exportar a PDF '}
                        <i className="fa fa-file-pdf-o" aria-hidden="true" />
                      </a>
                    );
                  }
                  return (
                    <a
                      variant="success"
                      className="pdfBtn"
                      href={doc.url}
                      key={doc._id}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {'Exportar a Excel '}
                      <i className="fa fa-file-excel-o" aria-hidden="true" />
                    </a>
                  );
                })
              : ERROR_MESSAGES.NO_DOCS_PICTURES}
          </ButtonToolbar>
        </div>
      </div>
    </div>
  );
};

Visit.defaultProps = {
  instructions: 'No hay instrucciones para esta orden'
};

Visit.propTypes = {
  instructions: PropTypes.string,
  location: PropTypes.string,
  interior: PropTypes.string,
  evidences: PropTypes.arrayOf(PropTypes.shape()),
  documents: PropTypes.arrayOf(PropTypes.shape())
};

export default Visit;
