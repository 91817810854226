const ERROR_MESSAGES = {
  INCORRECT_PASSWORD: 'Parece que la contraseña es incorrecta, por favor intenta de nuevo',
  STATUS_ERROR: 'Hubo un problema con el estatus seleccionado, por favor intenta de nuevo',
  INCORRECT_USER: 'Lo siento, parece que el usuario no existe!',
  DEFAULT_ERROR: 'Ups, parece que hubo un problema, por favor intenta de nuevo',
  DUPLICATE_USER: 'Este correo ya ha sido registrado, por favor utiliza otro',
  SESSION_EXPIRED: 'Su sesión ha expirado por favor inicie sesión nuevamente',
  INVALID_EMAIL: 'Por favor, ingrese un correo válido',
  INVALID_PASSWORD: 'Por favor, ingrese su contraseña',
  NAME_INVALID: 'Introduzca un nombre válido',
  PATERNAL_LN_INVALID: 'Introduzca un apellido paterno válido',
  MATERNAL_LN_INVALID: 'Introduzca un apellido materno válido',
  EMAIL_INVALID: 'Introduzca un email válido',
  TELEPHONE_INVALID: 'Introduzca un teléfono válido',
  PASSWORD_INVALID:
    'La contraseña debe tener mas de 6 caracteres y al menos una letra mayúscula, una minúscula, un número y un carácter especial !/$%@#*?&._-',
  NO_BUSINESS_PICTURES: 'No se han cargado las fotos del negocio',
  NO_CONTRACT_PICTURES: 'No se han cargado las fotos del contrato',
  NO_DOCS_PICTURES: 'No se han cargado documentos de la encuesta',
  NO_SIGNATURE: 'No se ha cargado la firma',
  PLEASE_SELECT_STATE: 'Por favor, selecciona un estado',
  TYPE_VALID_ADDRESS: 'Ingresa una dirección válida'
};

export default ERROR_MESSAGES;
