import { combineReducers } from 'redux';
import { reducer as board } from '../ducks/board';
import { reducer as user } from '../ducks/user';
import { reducer as orderDetail } from '../ducks/orderDetail';
import { reducer as groups } from '../ducks/groups';
import { reducer as loanInfo } from '../ducks/loanInfo';
import { reducer as loader } from '../ducks/loader';
import { reducer as alert } from '../ducks/alert';
import { reducer as ordersList } from '../ducks/orderList';
import { reducer as reports } from '../ducks/reports';

const mainReducer = combineReducers({
  board,
  orderDetail,
  user,
  groups,
  loanInfo,
  loader,
  ordersList,
  alert,
  reports
});

export default mainReducer;
