import api from '../api';

export const loginUser = (email, password) =>
  new Promise((resolve, reject) => {
    api
      .post('/login', {
        email,
        password
      })
      .then(response => {
        localStorage.setItem('token', response.data.token);
        api.setHeader('x-astro-token', response.data.token);
        return resolve(response.data);
      })
      .catch(error => {
        reject(error.data);
      });
  });

export const listUsers = (limit = 9999, page = 0, groupId) => {
  const paramsObj = {
    limit,
    page
  };
  if (groupId) {
    paramsObj.key = 'group';
    paramsObj.value = groupId;
  }
  return new Promise((resolve, reject) => {
    api
      .get('/users', paramsObj)
      .then(response => {
        return resolve(response.data);
      })
      .catch(error => {
        reject(error.data);
      });
  });
};

export const listOperators = (limit = 9999) => {
  return new Promise((resolve, reject) => {
    api
      .get('/users', {
        limit,
        key: 'permissions',
        value: 'orders_execute'
      })
      .then(response => {
        return resolve(response.data);
      })
      .catch(error => {
        reject(error.data);
      });
  });
};

export const getUserDetail = userId =>
  new Promise((resolve, reject) => {
    api
      .get(`/users/${userId}`)
      .then(response => {
        return resolve(response.data);
      })
      .catch(error => {
        reject(error.data);
      });
  });

export const updateUserGroup = (userId, groupId) =>
  new Promise((resolve, reject) => {
    api
      .patch(`/users/${userId}/add-group`, {
        groupId
      })
      .then(response => {
        return resolve(response.data);
      })
      .catch(error => {
        reject(error.data);
      });
  });

export const editUser = (userId, userData) =>
  new Promise((resolve, reject) => {
    api
      .patch(`/users/${userId}`, userData)
      .then(response => {
        if (response.status === 401) {
          api.logOut();
        }
        return resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });

export const uploadFileUrl = (userId, fileName, file) =>
  new Promise((res, reject) => {
    api
      .post(`/users/${userId}/profile-picture-url`, {
        fileName
      })
      .then(data => {
        if (data && data.ok) {
          fetch(data.data.signedUrl, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': file.type
            }
          })
            .then(response => {
              if (response.statusText === 'OK') {
                editUser(userId, { pictureURL: data.data.fileUrl }).then(dataU => {
                  if (dataU._id) res(true);
                });
              } else {
                reject(new Error('dont upload image'));
              }
            })
            .catch(() => {
              reject(new Error('dont upload image'));
            });
        }
      });
  });

export const createUser = user =>
  new Promise((resolve, reject) => {
    api
      .post('/users', {
        name: user.name,
        paternalLastName: user.paternalLastName,
        maternalLastName: user.maternalLastName,
        phone: user.phone,
        email: user.email,
        password: user.password,
        group: user.group,
        permissions: user.permissions,
        state: user.state
      })
      .then(response => {
        if (response.status === 401) {
          api.logOut();
        }
        return resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
