import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { prettyName } from '../../utils/nameUtils';
import { prettyDate } from '../../utils/calendarUtils';
import { statusTranslatorSimplified } from '../../utils/statusUtils';
import mexStates from '../../constants/mexStates';
import './orderList.scss';

const TableOrder = ({ ordersList, page, setPage }) => (
  <div>
    <Table
      className="tableOrders"
      style={{ backgroundColor: '#fff' }}
      striped
      bordered
      hover
      responsive
      variant="light"
    >
      <thead>
        <tr>
          <th>Cliente</th>
          <th>Estado</th>
          <th>Folio #</th>
          <th>Operador</th>
          <th>Status</th>
          <th>Servicio</th>
          <th>Fecha Agendada</th>
        </tr>
      </thead>
      <tbody>
        {ordersList.map(order => {
          const orderState = mexStates.filter(
            state => Number(state.value) === Number(order.location.state)
          );
          return (
            <tr key={order._id} className="tableRow trOrders">
              <td className="tdLink">
                <Link target="_blank" className="tableLink" to={`/orderDetail/${order._id}`}>
                  <div className="tableLink">
                    {order.client &&
                      prettyName(
                        order.client.name,
                        order.client.paternalLastName,
                        order.client.maternalLastName
                      )}
                  </div>
                </Link>
              </td>
              <td className="tdLink">
                <Link target="_blank" className="tableLink" to={`/orderDetail/${order._id}`}>
                  <div className="tableLink">{orderState.length > 0 && orderState[0].label}</div>
                </Link>
              </td>
              <td className="tdLink">
                <Link target="_blank" className="tableLink" to={`/orderDetail/${order._id}`}>
                  <div className="tableLink">{order.contractNumber}</div>
                </Link>
              </td>
              <td className="tdLink">
                <Link target="_blank" className="tableLink" to={`/orderDetail/${order._id}`}>
                  <div className="tableLink">
                    {order.executor &&
                      prettyName(
                        order.executor.name,
                        order.executor.paternalLastName,
                        order.executor.maternalLastName
                      )}
                  </div>
                </Link>
              </td>
              <td className="tdLink">
                <Link target="_blank" className="tableLink" to={`/orderDetail/${order._id}`}>
                  <div className="tableLink">{statusTranslatorSimplified(order.status).status}</div>
                </Link>
              </td>
              <td className="tdLink">
                <Link target="_blank" className="tableLink" to={`/orderDetail/${order._id}`}>
                  <div className="tableLink">{order.service.name}</div>
                </Link>
              </td>
              <td style={{ minWidth: '160px' }}>
                <Link target="_blank" className="tableLink" to={`/orderDetail/${order._id}`}>
                  <div className="tableLink">{prettyDate(order.scheduledDate)}</div>
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
    <div className="pageCont">
      {page > 0 && (
        <i
          className="fa fa-chevron-left arrowLeft"
          aria-hidden="true"
          onClick={() => setPage(page - 1)}
        />
      )}
      {page + 1}
      {ordersList.length >= 50 && (
        <i
          className="fa fa-chevron-right arrowRight"
          aria-hidden="true"
          onClick={() => setPage(page + 1)}
        />
      )}
    </div>
  </div>
);

TableOrder.propTypes = {
  ordersList: PropTypes.arrayOf(PropTypes.shape()),
  page: PropTypes.number,
  setPage: PropTypes.func
};

export default TableOrder;
