import { board as initialState } from '../config/initialState.json';
import { getOrdersList } from '../api/services/order';
import { showLoader, hideLoader } from './loader';
import { showAlert } from './alert';
import errorHandler from '../utils/errorHandler';
import {
  orderUnassigned,
  orderAssigned,
  orderOnTheWay,
  orderWithClient,
  orderWithEvidence,
  orderWasCompleted,
  orderIncompleted
} from '../utils/statusUtils';

// ACTIONS
export const SET_ORDERS = 'SET_ORDERS';

export const setOrders = orders => ({ type: SET_ORDERS, orders });

export const getOrders = (startDate, endDate) => dispatch => {
  dispatch(showLoader());
  getOrdersList(startDate, endDate)
    .then(response => {
      const ordersUnassigned = response.filter(order => orderUnassigned(order.status));
      const ordersAssigned = response.filter(order => orderAssigned(order.status));
      const ordersOnTheWay = response.filter(
        order => orderOnTheWay(order.status) || orderWithClient(order.status)
      );
      const ordersWasCompleted = response.filter(
        order =>
          orderWasCompleted(order.status) ||
          orderIncompleted(order.status) ||
          orderWithEvidence(order.status)
      );
      const ordersTransformers = {
        unassigned: ordersUnassigned,
        assigned: ordersAssigned,
        ongoing: ordersOnTheWay,
        finished: ordersWasCompleted
      };
      dispatch(setOrders(ordersTransformers));
      dispatch(hideLoader());
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

// REDUCER
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERS: {
      return {
        ...state,
        orders: action.orders
      };
    }
    default:
      return state;
  }
};
