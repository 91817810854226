import React, { useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import PropTypes from 'prop-types';

const renderActiveShape = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
  payload,
  percent,
  value
}) => {
  const RADIAN = Math.PI / 180;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
        {value}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`${Math.round(percent * 100)}%`}
      </text>
    </g>
  );
};

renderActiveShape.propTypes = {
  cx: PropTypes.number,
  payload: PropTypes.shape(),
  cy: PropTypes.number,
  midAngle: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  startAngle: PropTypes.number,
  endAngle: PropTypes.number,
  fill: PropTypes.number,
  percent: PropTypes.number,
  value: PropTypes.number
};

const StatusPieChart = ({ ordersByStatus }) => {
  const [pieIndex, setPieIndex] = useState(0);
  const onPieEnter = (info, index) => {
    // change activeIndex here
    setPieIndex(index);
  };
  const { completed, imcompleted, recolected, onTheWay } = ordersByStatus;
  const data = [
    { name: 'Completadas', value: completed, color: '#16a085' },
    { name: 'Incompletas', value: imcompleted, color: '#e74c3c' },
    { name: 'Recolectadas', value: recolected, color: '#8e44ad' },
    { name: 'En Camino', value: onTheWay, color: '#f39c12' }
  ];

  return (
    <div className="pieChart">
      <h5 className="pieTitle">
        Total de Órdenes:
        <span className="pieTotal">{` ${completed + imcompleted + recolected + onTheWay}`}</span>
      </h5>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            activeIndex={pieIndex}
            activeShape={renderActiveShape}
            data={data}
            innerRadius={80}
            outerRadius={110}
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {data.map(entry => (
              <Cell fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

StatusPieChart.propTypes = {
  ordersByStatus: PropTypes.shape()
};

export default StatusPieChart;
