import React from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import './reports.scss';

const WeekDaysChart = ({ ordersByDayOfWeek }) => {
  const renderTooltip = props => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0] && payload[0].payload;
      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #999',
            margin: 0,
            padding: 10
          }}
        >
          <p>{data.hour}h</p>
          <p>
            <span>Total: </span>
            {data.total}
          </p>
        </div>
      );
    }
    return null;
  };

  renderTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.shape()
  };

  const parseDomain = () => [
    0,
    Math.max(
      Math.max.apply(
        null,
        ordersByDayOfWeek.sunday.map(entry => entry.total)
      ),
      Math.max.apply(
        null,
        ordersByDayOfWeek.monday.map(entry => entry.total)
      ),
      Math.max.apply(
        null,
        ordersByDayOfWeek.tuesday.map(entry => entry.total)
      ),
      Math.max.apply(
        null,
        ordersByDayOfWeek.wendsday.map(entry => entry.total)
      ),
      Math.max.apply(
        null,
        ordersByDayOfWeek.thursday.map(entry => entry.total)
      ),
      Math.max.apply(
        null,
        ordersByDayOfWeek.friday.map(entry => entry.total)
      ),
      Math.max.apply(
        null,
        ordersByDayOfWeek.saturday.map(entry => entry.total)
      )
    )
  ];

  const domain = parseDomain();
  const range = [16, 225];

  return (
    <div>
      <div className="bubleChart">
        <ResponsiveContainer>
          <ScatterChart
            margin={{
              top: 10,
              right: 0,
              bottom: 0,
              left: 0
            }}
          >
            <XAxis
              type="category"
              dataKey="hour"
              interval={0}
              tickLine={{ transform: 'translate(0, -6)' }}
            />
            <YAxis
              type="number"
              dataKey="index"
              name="sunday"
              height={10}
              width={80}
              tick={false}
              tickLine={false}
              axisLine={false}
              label={{ value: 'Domingo', position: 'insideRight' }}
            />
            <ZAxis type="number" dataKey="total" domain={domain} range={range} />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              wrapperStyle={{ zIndex: 100 }}
              content={renderTooltip}
            />
            <Scatter data={ordersByDayOfWeek.sunday} fill="#825CA4" />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <div className="bubleChart">
        <ResponsiveContainer>
          <ScatterChart
            margin={{
              top: 10,
              right: 0,
              bottom: 0,
              left: 0
            }}
          >
            <XAxis
              type="category"
              dataKey="hour"
              name="hour"
              interval={0}
              tickLine={{ transform: 'translate(0, -6)' }}
            />
            <YAxis
              type="number"
              dataKey="index"
              height={10}
              width={80}
              tick={false}
              tickLine={false}
              axisLine={false}
              label={{ value: 'Lunes', position: 'insideRight' }}
            />
            <ZAxis type="number" dataKey="total" domain={domain} range={range} />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              wrapperStyle={{ zIndex: 100 }}
              content={renderTooltip}
            />
            <Scatter data={ordersByDayOfWeek.monday} fill="#825CA4" />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <div className="bubleChart">
        <ResponsiveContainer>
          <ScatterChart
            margin={{
              top: 10,
              right: 0,
              bottom: 0,
              left: 0
            }}
          >
            <XAxis
              type="category"
              dataKey="hour"
              name="hour"
              interval={0}
              tickLine={{ transform: 'translate(0, -6)' }}
            />
            <YAxis
              type="number"
              dataKey="index"
              height={10}
              width={80}
              tick={false}
              tickLine={false}
              axisLine={false}
              label={{ value: 'Martes', position: 'insideRight' }}
            />
            <ZAxis type="number" dataKey="total" domain={domain} range={range} />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              wrapperStyle={{ zIndex: 100 }}
              content={renderTooltip}
            />
            <Scatter data={ordersByDayOfWeek.tuesday} fill="#825CA4" />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <div className="bubleChart">
        <ResponsiveContainer>
          <ScatterChart
            margin={{
              top: 10,
              right: 0,
              bottom: 0,
              left: 0
            }}
          >
            <XAxis
              type="category"
              dataKey="hour"
              name="hour"
              interval={0}
              tickLine={{ transform: 'translate(0, -6)' }}
            />
            <YAxis
              type="number"
              dataKey="index"
              height={10}
              width={80}
              tick={false}
              tickLine={false}
              axisLine={false}
              label={{ value: 'Miercoles', position: 'insideRight' }}
            />
            <ZAxis type="number" dataKey="total" domain={domain} range={range} />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              wrapperStyle={{ zIndex: 100 }}
              content={renderTooltip}
            />
            <Scatter data={ordersByDayOfWeek.wendsday} fill="#825CA4" />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <div className="bubleChart">
        <ResponsiveContainer>
          <ScatterChart
            margin={{
              top: 10,
              right: 0,
              bottom: 0,
              left: 0
            }}
          >
            <XAxis
              type="category"
              dataKey="hour"
              name="hour"
              interval={0}
              tickLine={{ transform: 'translate(0, -6)' }}
            />
            <YAxis
              type="number"
              dataKey="index"
              height={10}
              width={80}
              tick={false}
              tickLine={false}
              axisLine={false}
              label={{ value: 'Jueves', position: 'insideRight' }}
            />
            <ZAxis type="number" dataKey="total" domain={domain} range={range} />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              wrapperStyle={{ zIndex: 100 }}
              content={renderTooltip}
            />
            <Scatter data={ordersByDayOfWeek.thursday} fill="#825CA4" />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <div className="bubleChart">
        <ResponsiveContainer>
          <ScatterChart
            margin={{
              top: 10,
              right: 0,
              bottom: 0,
              left: 0
            }}
          >
            <XAxis
              type="category"
              dataKey="hour"
              name="hour"
              interval={0}
              tickLine={{ transform: 'translate(0, -6)' }}
            />
            <YAxis
              type="number"
              dataKey="index"
              height={10}
              width={80}
              tick={false}
              tickLine={false}
              axisLine={false}
              label={{ value: 'Viernes', position: 'insideRight' }}
            />
            <ZAxis type="number" dataKey="total" domain={domain} range={range} />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              wrapperStyle={{ zIndex: 100 }}
              content={renderTooltip}
            />
            <Scatter data={ordersByDayOfWeek.friday} fill="#825CA4" />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <div className="bubleChart">
        <ResponsiveContainer>
          <ScatterChart
            margin={{
              top: 10,
              right: 0,
              bottom: 0,
              left: 0
            }}
          >
            <XAxis
              type="category"
              dataKey="hour"
              name="hour"
              interval={0}
              tickLine={{ transform: 'translate(0, -6)' }}
            />
            <YAxis
              type="number"
              dataKey="index"
              height={10}
              width={80}
              tick={false}
              tickLine={false}
              axisLine={false}
              label={{ value: 'Sabado', position: 'insideRight' }}
            />
            <ZAxis type="number" dataKey="total" domain={domain} range={range} />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              wrapperStyle={{ zIndex: 100 }}
              content={renderTooltip}
            />
            <Scatter data={ordersByDayOfWeek.saturday} fill="#825CA4" />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

WeekDaysChart.propTypes = {
  ordersByDayOfWeek: PropTypes.shape()
};

export default WeekDaysChart;
