import { ordersList as initialState } from '../config/initialState.json';
import { getOrdersList } from '../api/services/order';
import { showLoader, hideLoader } from './loader';
import { showAlert } from './alert';
import errorHandler from '../utils/errorHandler';

const SET_ORDERS_LIST = 'SET_ORDERS_LIST';

export const setOrdersList = ordersList => ({ type: SET_ORDERS_LIST, ordersList });

export const getOrders = (
  startDate,
  endDate,
  page,
  startStatus,
  endStatus,
  state,
  group
) => dispatch => {
  dispatch(showLoader());
  getOrdersList(startDate, endDate, page, startStatus, endStatus, state, group, true, 50)
    .then(response => {
      dispatch(setOrdersList(response));
      dispatch(hideLoader());
    })
    .catch(error => {
      dispatch(hideLoader());
      dispatch(showAlert('danger', errorHandler(error)));
    });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERS_LIST:
      return [...action.ordersList];
    default:
      return state;
  }
};
